import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Alert from 'react-s-alert'
import Picker from '@emoji-mart/react'
import twitter from 'twitter-text'
import ClipLoader from 'react-spinners/ClipLoader'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { transparentize, triangle } from 'polished'
import debounce from 'lodash.debounce'
import moment from 'moment-timezone'
import { COLOR_CONSTANTS, radius, space } from 'theme'
import {
  AI_TYPE_POST,
  ERROR_MESSAGE,
  FACEBOOK,
  FEATURE_ADD_TO_QUEUE,
  FEATURE_LINK_SHORTENER,
  FEATURE_UNIVERSAL_MENTIONS,
  FEATURE_UTM_TRACKING,
  IMAGE,
  VIDEO,
  IMAGE_DYNAMIC,
  VIDEO_DYNAMIC,
  GOOGLE,
  INSTAGRAM,
  LABELS_TYPE_PUBLICATION,
  LINKEDIN,
  PINTEREST,
  REDDIT,
  SNIPPET_TYPE_CAPTION,
  TIKTOK,
  TWITTER,
  VIMEO,
  YOUTUBE,
  DOCUMENT,
  SNAPCHAT,
  VISTAPAGE,
  TUMBLR,
  THREADS,
  USER_TYPES,
  PROFILE_TYPE_TWITTER_FREE,
  BLUESKY,
  CUSTOM,
  DIRECTION_LEFT_TO_RIGHT,
  DIRECTION_RIGHT_TO_LEFT,
} from 'consts'
import { truncate, useAsyncState } from 'helpers'
import request from 'utils/request'
import { getSocialNetworkIconByName } from 'utils/socialNetworks'
import { checkIfPlanHasFeatureEnabled, handleChangeUserIntroFeature } from 'utils/feature'
import errorHelper from 'utils/errorHelper'
import { Text } from 'components/atoms/Typography'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import Icon from 'components/atoms/Icon'
import Switch from 'components/atoms/Switch'
// eslint-disable-next-line import/no-named-default
import Image, { default as ImageComponent } from 'components/atoms/Image'
import Input from 'components/atoms/Input'
import Tooltip from 'components/molecules/Tooltip'
import ErrorLabel from 'components/atoms/ErrorLabel'
import Checkbox from 'components/atoms/Checkbox'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import LabelsSelect from 'shared/LabelEditModal/components/LabelsSelect'
import { getLabels } from 'shared/LabelEditModal/helpers'
import UrlTrackingParameters from 'routes/Settings/PublishingSettings/components/UrlTrackingParameters'
import VariablesModal from 'routes/Settings/PublishingSettings/components/VariablesModal'
import { getProfileFlavor } from 'routes/Settings/Profiles/helpers'
import TextAreaComponent from './components/TextAreaComponent'
import UploadMediaComponent from './components/UploadMediaComponent'
// eslint-disable-next-line import/no-cycle
import UploadedMediasComponent from './components/UploadedMediasComponent'
import HashTagSelector from '../HashTagSelector'
import PostPreviewComponent from '../PostPreviewComponent'
import MentionSelector from '../MentionSelector'
import {
  CUSTOM_TYPE,
  DEFAULT_UTM_PARAMS,
  FAILED,
  NETWORK_LIMITS,
  POST_AT,
  POST_EMOJI,
  POST_HASHTAG,
  POST_IMAGE,
  POST_LINK,
  POST_MEDIA_CLASS_NAME,
  POST_SNIPPET,
  POST_TEXT_EDITOR,
  POST_VARIABLE,
  POST_VIDEO,
  POST_DOCUMENT,
  POST_MIXED_MEDIA,
  PREVIEW_MEDIA_CLASS_NAME,
  PUBLISH_AS_SLIDESHOW,
  PUBLISH_AS_STORY,
  PUBLISH_AS_REELS,
  PUBLISH_AS_CAROUSEL,
  PUBLISH_AS_SPOTLIGHT,
  PUBLISH_AS_SAVED_STORY,
  PUBLISHED,
  PUBLISHING_OPTION_NOTIFICATION,
  ROUTE_MENTIONS,
  ROUTE_TRACKING,
  ROUTE_VARIABLE,
  ROUTE_MEDIA,
  ROUTE_BOOST_CONFIGURATION,
  URL_REGEXP,
  PUBLISH_DRAFT,
  DRAFT,
  VIDEO_MIME_TYPES,
} from '../../consts'
import {
  checkPostTextForNotCreatedMentions,
  checkPostTextForNotCreatedVariables,
  getReminderNotificationsList,
  instagramTextSymbolCounter,
  mentionsTransformer,
  transformTextToUnicode,
  transformTextVariantsToUnicodeVariant,
  variableTransformer,
  deHibernateMedias,
  getSelectedProfileGroupsAndTimezone,
  blueskyGetPostLength,
} from '../../helpers'
import { usePostCreationDispatch, usePostCreationContext } from '../../contexts/PostCreationContext'
import OpenAIModal from '../OpenAIModal'
import SnippetSelector from '../SnippetSelector'
import VariableSelector from '../VariableSelector'
import CalendarItemTooltip from '../CalendarItemTooltip'
import AdvocacyComponent from './components/AdvocacyComponent'
import CompliancePreviewModal from './components/CompliancePreviewModal'

const StyledBox = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  border-radius: ${radius.m};
`

const StyledTextAreaWrapper = styled(StyledBox)`
  ${({ hasError, theme }) => hasError && `border: 1px solid ${theme.colors.error};`}
`

const StyledNetworkLimitsWrapper = styled(Flex)`
  gap: ${space.xs};
`

const IconWrapper = styled(Box)`
  padding: 0 ${space.s};
  width: 36px;
  height: 36px;
  cursor: pointer;
  svg {
    fill: ${({ theme }) => theme.colors.composer_icons_color};
  }
  ${({ isActive, theme }) =>
    isActive &&
    `
   svg {
      fill: ${theme.colors.primary};
   }
  `}
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`

const PostWrapper = styled(Flex)`
  border-left: 1px solid ${({ theme }) => theme.colors.border_color};
  border-right: 1px solid ${({ theme }) => theme.colors.border_color};
  overflow-x: hidden;
`

const StyledHiddenIconWrapper = styled(Flex)`
  display: none;
  position: absolute;
  background: ${({ theme }) => transparentize(0.2, theme.colors.white)};
  border-radius: ${radius.pill};
  padding: ${space.xs};
  z-index: 1;
`

const PostMediaWrapper = styled(Flex)`
  cursor: pointer;
  border-radius: ${radius.l};
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  flex-direction: column;
  text-align: center;
  &:hover {
    ${StyledHiddenIconWrapper} {
      display: flex;
    }
    box-shadow: 0 0 2px 0 ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  }
`

const StyledCopyIconWrapper = styled(Flex)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  border-radius: ${radius.pill};
  cursor: pointer;
  background-color: transparent;
  &:hover {
    background-color: ${({ theme }) => theme.colors.icon_background_hover};
  }
`

const PostTextHelperComponentWrapper = styled(Box)`
  position: absolute;
  top: 36px;
  z-index: 3;
  width: 100%;
  min-width: ${({ minWidth }) => minWidth || '320px'};
  max-width: ${({ maxWidth }) => maxWidth || '380px'};
  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: 0 4px 10px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
`

const StyledLabel = styled.label`
  cursor: pointer;
  position: relative;
  width: 80px;
  height: 80px;
  margin-right: ${space.m};
  &:hover {
    opacity: 0.8;
  }
`

const StyledImage = styled(ImageWithFallback)`
  cursor: pointer;
  object-fit: cover;
  opacity: 0.5;
`

const LinkControlsWrapper = styled(Box)`
  cursor: pointer;
`

const AddMediaWrapper = styled(Flex)`
  cursor: pointer;
`

const CheckBoxWrapper = styled(Box)`
  cursor: pointer;
`

const TextWrapper = styled(Text)`
  cursor: pointer;
`

const MessageWrapper = styled(Flex)`
  background-color: ${({ color, theme }) => transparentize(0.9, color || theme.colors.error)};
  border-radius: ${radius.l};
  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
  }
`

const StyledVariantOffsetWrapper = styled(Flex)`
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.background_item_light};
  }
`

const StyledProgressWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 15;
  background: ${({ theme }) => theme.colors.background};
  opacity: 0.6;
`

const StyledLearnMoreText = styled(Text)`
  text-decoration: none;
`

const Triangle = styled.div`
  position: absolute;
  top: -19px;
  left: 0;
  ${({ theme }) =>
    triangle({ pointingDirection: 'top', width: '14px', height: '11px', foregroundColor: theme.colors.primary })}
`

const StyledAIPrompt = styled(Flex)`
  position: fixed;
  width: auto;
  background-color: ${({ theme }) => theme.colors.background};
  padding: ${space.s};
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: ${radius.l};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`

const StyledAIPromptRefine = styled(Flex)`
  display: none;
  position: fixed;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  border-radius: ${radius.l};
  padding: ${space.xxs} ${space.xs};
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 0 4px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  transition: left 0.3s ease 0s;
`

const StyledDirectionWrapper = styled(Flex)`
  width: 24px;
  height: 24px;
  border-radius: ${radius.pill};
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${({ theme }) => theme.colors.icon_background_hover};

    svg {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`

const LOCAL_STORAGE_SHOW_MENTION_SELECTOR = 'VistaSocialMentionOpened'

const OPEN_AI_MODAL_OPTIONS = {
  isOpen: false,
  text: '',
  savedGeneratedTexts: [],
  generateOnOpen: false,
  rephrase: false,
  selectionStart: null,
  selectionEnd: null,
}

const VARIABLES_MODAL_OPTIONS = {
  isOpen: false,
  profiles: null,
  variables: null,
  callbackFunction: null,
  needValidation: true,
}

const DEFAULT_COMPLIANCE_PREVIEW_MODAL = {
  isOpen: false,
  entityIds: [],
}

const MAX_PROCESSED_URLS_CALLED_TIMES = 30
const PROCESSED_URLS_CALLED_TIMEOUT = 6000 // MAX_PROCESSED_URLS_CALLED_TIMES*PROCESSED_URLS_CALLED_TIMEOUT must always be 180 seconds, because lambda processing timeout is 3 minutes

const PostCreationComponent = forwardRef(
  (
    {
      onChangeUpdatePost,
      postForEdit,
      checkForErrors = true,
      selectedEntities,
      uploadingMediasAmount,
      setUploadingMediasAmount,
      confirm,
      postCreationComponentDataLoadingCallback,
      isAdvocacy,
      whenToPublish,
      ...props
    },
    ref
  ) => {
    const textAreaRef = useRef(null)
    const uploadMediaComponentRef = useRef(null)
    const emojiRef = useRef(null)
    const hashtagRef = useRef(null)
    const hashTagSelectorRef = useRef(null)
    const snippetRef = useRef(null)
    const snippetSelectorRef = useRef(null)
    const mentionsRef = useRef(null)
    const mentionSelectorRef = useRef(null)
    const textVariantRef = useRef(null)
    const variableRef = useRef(null)
    const inputLinkRef = useRef(null)
    const tooltipRef = useRef(null)

    const {
      video_processing: user_settings_video_processing = true,
      image_processing: user_settings_image_processing = true,
      direction = DIRECTION_LEFT_TO_RIGHT,
    } = props.user.settings || {}

    const { mediaProcessingIntervalsDispatch, postDispatch } = usePostCreationDispatch()
    const { mediaProcessingIntervals, post } = usePostCreationContext()

    const [user, setUser] = useState(props.user)
    const [selectedProfiles, setSelectedProfiles] = useState([])
    const [showPostTextHelperComponent, setShowPostTextHelperComponent] = useState(null)
    const [dataForCustomizations, setDataForCustomizations] = useState(
      JSON.parse(JSON.stringify(props.dataForCustomizations))
    )
    const [agencyLabels, setAgencyLabels] = useState([])
    const [agencyMentionsOptions, setAgencyMentionsOptions] = useState({ isLoading: false, data: [] })
    const [agencyMentionsSkipped, setAgencyMentionsSkipped] = useState([])
    const [agencyTrackingTypes, setAgencyTrackingTypes] = useState([])
    const [boostsOptions, setBoostsOptions] = useState({ isLoading: false, data: [] })
    const [postErrors, setPostErrors] = useState({ networksErrors: {}, mediaUploadingError: {} })
    const [reminderNotificationsListByEntity, setReminderNotificationsListByEntity] = useState({})
    const [savedVariables, setSavedVariables] = useState([])
    const [profileVariables, setProfileVariables] = useState(null)
    const [intro, setIntro] = useState({})
    const [textVariantOffsets, setTextVariantOffsets] = useState([])
    const [forceCloseNetworkPreviewCaption, setForceCloseNetworkPreviewCaption] = useState(null)
    const [isDirectionChanging, setIsDirectionChanging] = useState(false)

    const { ai_assistant_prompt = true } = intro || {}

    const isAdvocate = user.type === USER_TYPES.ADVOCATE

    const {
      postText = '',
      postImages = [],
      postVideos = [],
      postDocuments = [],
      postMixedMedias = [],
      link,
      result: { error = '' } = {},
      postComponent,
      labels = [],
      review,
      status,
      customizations,
      advocacy,
      advocacy_gid,
    } = post

    const {
      video_processing = typeof user_settings_video_processing !== 'undefined' ? user_settings_video_processing : true,
    } = customizations || {}

    const {
      image_processing = typeof user_settings_image_processing !== 'undefined' ? user_settings_image_processing : true,
    } = customizations || {}

    const {
      url = '',
      title = '',
      description = '',
      picture_url = '',
      // thumbnail_url = '',
      isUploadingLinkImage = false,
      tracking: { params: linkTrackingParams = DEFAULT_UTM_PARAMS } = {},
      save_utm_tracking = true,
      utm_tracking,
      shorten,
      active: linkActive,
      present: linkPresent,
    } = link || {}

    let { type: shortenType } = shorten || {}

    const { reviewer: { label: reviewerName = '' } = {} } = review || {}

    const [isPostFormDirty, setIsPostFormDirty] = useState(false)
    const [openAIModalOptions, setOpenAIModalOptions] = useState({ ...OPEN_AI_MODAL_OPTIONS })
    const [isMentionsModalOpen, setIsMentionsModalOpen] = useAsyncState(false)
    const [mentionsModalData, setMentionsModalData] = useState(null)
    const [featuresEnabled, setFeaturesEnabled] = useState({
      [FEATURE_LINK_SHORTENER]: { enabled: true },
      [FEATURE_UNIVERSAL_MENTIONS]: { enabled: true },
      [FEATURE_UTM_TRACKING]: { enabled: true },
      [FEATURE_ADD_TO_QUEUE]: { enabled: true },
    })
    const [variablesModalOptions, setVariablesModalOptions] = useState({ ...VARIABLES_MODAL_OPTIONS })
    const [compliancePreviewModalOptions, setCompliancePreviewModalOptions] = useState({
      ...DEFAULT_COMPLIANCE_PREVIEW_MODAL,
    })
    const [, setSpecialRerenderKey] = useState(null)

    if (!shortenType) {
      if (featuresEnabled[FEATURE_LINK_SHORTENER].enabled) {
        shortenType = 'DEFAULT'
      } else {
        shortenType = 'NONE'
      }
    }

    let linkUrlStates = {}

    if (uploadMediaComponentRef && uploadMediaComponentRef.current) {
      linkUrlStates = uploadMediaComponentRef.current.getLinkUrlStates()
    }

    useImperativeHandle(ref, () => ({
      handleCheckPostTextForNotCreatedMentions({ callbackFunction }) {
        const { settings = {} } = user

        const universal_mentions =
          typeof settings.universal_mentions === 'undefined' ? false : settings.universal_mentions || false

        if (universal_mentions) {
          const { shouldOpenMentionsModal, mentionsModalData } = checkPostTextForNotCreatedMentions({
            post,
            selectedProfiles,
          })

          if (shouldOpenMentionsModal) {
            mentionsModalData.callbackFunction = callbackFunction
            setMentionsModalData({ ...mentionsModalData })
            if (showPostTextHelperComponent !== POST_AT) {
              // eslint-disable-next-line no-use-before-define
              handleClickSelectPostTextHelperComponent(POST_AT)
            }
            setIsMentionsModalOpen(true)
            return true
          }
        }

        return false
      },
      handleCheckPostTextForNotCreatedVariables({ callbackFunction }) {
        const { shouldOpenVariablesModal, variablesProfiles, notFoundVariables } = checkPostTextForNotCreatedVariables({
          post,
          selectedProfiles,
          savedVariables,
        })

        if (shouldOpenVariablesModal) {
          setVariablesModalOptions({
            ...{ isOpen: true, callbackFunction, profiles: variablesProfiles, variables: notFoundVariables },
          })
          return true
        }

        return false
      },
      handleCheckPostForError(data) {
        // eslint-disable-next-line no-use-before-define
        return handleCheckPostForError(data)
      },
      handleReturnPost() {
        return { post }
      },
      handleHasPostChangedByUser() {
        return post.hasBeenChanged
      },
      setUploadingMedias(medias) {
        uploadMediaComponentRef.current.setUploadingMedias(medias)
      },
    }))

    const handleMouseClick = (e) => {
      if (showPostTextHelperComponent === POST_EMOJI && emojiRef.current && !emojiRef.current.contains(e.target)) {
        setShowPostTextHelperComponent(null)
      } else if (
        showPostTextHelperComponent === POST_HASHTAG &&
        hashtagRef.current &&
        !hashtagRef.current.contains(e.target) &&
        (!hashTagSelectorRef.current ||
          (hashTagSelectorRef.current && !hashTagSelectorRef.current.getHashTagModalState()))
      ) {
        setShowPostTextHelperComponent(null)
      } else if (
        showPostTextHelperComponent === POST_AT &&
        mentionsRef.current &&
        !mentionsRef.current.contains(e.target) &&
        (!mentionSelectorRef.current ||
          (mentionSelectorRef.current && !mentionSelectorRef.current.getMentionsModalState()))
      ) {
        setShowPostTextHelperComponent(null)
      } else if (
        showPostTextHelperComponent === POST_TEXT_EDITOR &&
        textVariantRef.current &&
        !textVariantRef.current.contains(e.target)
      ) {
        setShowPostTextHelperComponent(null)
      } else if (
        showPostTextHelperComponent === POST_SNIPPET &&
        snippetRef.current &&
        !snippetRef.current.contains(e.target) &&
        (!snippetSelectorRef.current ||
          (snippetSelectorRef.current && !snippetSelectorRef.current.getSnippetModalState()))
      ) {
        setShowPostTextHelperComponent(null)
      } else if (
        showPostTextHelperComponent === POST_VARIABLE &&
        variableRef.current &&
        !variableRef.current.contains(e.target)
      ) {
        setShowPostTextHelperComponent(null)
      }
    }

    const checkIfComposerDataIsLoading = () => {
      if (agencyMentionsOptions.isLoading || boostsOptions.isLoading) {
        postCreationComponentDataLoadingCallback({ isLoading: true })
      } else {
        postCreationComponentDataLoadingCallback({ isLoading: false })
      }
    }

    const checkPostForErrorImagesHelper = ({
      postComponent,
      postImages,
      code,
      data,
      maxImages,
      minImageAspectRatio,
      maxImageAspectRatio,
      minImageHeight,
      minImageWidth,
      maxImageHeight,
      maxImageWidth,
      maxImageSize,
      imageExtensions,
      flags,
      warning_flags,
      errors,
      media_aspect_ratios,
      media_type,
    }) => {
      if (
        (postComponent === POST_IMAGE || postComponent === POST_MIXED_MEDIA) &&
        postImages &&
        postImages.length !== 0
      ) {
        const limitedPostImages = postImages.length > maxImages ? postImages.slice(0, maxImages) : postImages
        for (const image of limitedPostImages) {
          const { id, isNew, aspect_ratio, width, height, size, extension } = image
          image[code] = {}

          if (!isNew) {
            let updatedAspectRatio = aspect_ratio ? Number(aspect_ratio).toFixed(2) : 0
            let updatedWidth = width
            let updatedHeight = height

            if (!updatedAspectRatio || !updatedWidth || !updatedHeight) {
              let element = document.getElementById(
                `${PREVIEW_MEDIA_CLASS_NAME}${data && data.id ? `-${data.id}` : ''}-${id}`
              )

              if (!element) {
                element = document.getElementById(`${POST_MEDIA_CLASS_NAME}-${id}`)
              }

              if (element) {
                const { naturalWidth, naturalHeight } = element
                if (naturalWidth && naturalHeight) {
                  updatedAspectRatio = Number((naturalWidth / naturalHeight).toFixed(2))
                  image.aspect_ratio = updatedAspectRatio
                  updatedWidth = naturalWidth
                  image.width = naturalWidth
                  updatedHeight = naturalHeight
                  image.height = naturalHeight
                }
              }
            }

            if (minImageAspectRatio && maxImageAspectRatio) {
              if (typeof image[code].hasAspectRatioError === 'boolean' && image[code].hasAspectRatioError) {
                if (code === INSTAGRAM && media_type !== 'CAROUSEL') {
                  warning_flags.postImageAspectRatioLimitWarning = id
                } else {
                  flags.postImageAspectRatioLimit = id
                }
              } else if (typeof image[code].hasAspectRatioError !== 'boolean') {
                image[code].hasAspectRatioError = false

                if (updatedAspectRatio) {
                  if (updatedAspectRatio < minImageAspectRatio || updatedAspectRatio > maxImageAspectRatio) {
                    if (code !== INSTAGRAM || media_type === 'CAROUSEL') {
                      flags.postImageAspectRatioLimit = id
                      image[code].hasAspectRatioError = true
                    } else {
                      warning_flags.postImageAspectRatioLimitWarning = id
                    }
                  }
                } else {
                  delete image[code].hasAspectRatioError
                  errors.mediaUploadingError = { isMediaUploading: true, mediaType: IMAGE }
                }
              }
            }

            if ((minImageHeight && minImageWidth) || (maxImageHeight && maxImageWidth)) {
              if (typeof image[code].hasResolutionError === 'boolean' && image[code].hasResolutionError) {
                flags.postImageResolutionLimit = id
              } else if (typeof image[code].hasResolutionError !== 'boolean') {
                image[code].hasResolutionError = false
                if (updatedWidth && updatedHeight) {
                  if (minImageWidth && minImageHeight) {
                    if (updatedWidth < minImageWidth || updatedHeight < minImageHeight) {
                      flags.postImageResolutionLimit = id
                      image[code].hasResolutionError = true
                    }
                  }

                  if (maxImageWidth && maxImageHeight && !image_processing) {
                    if (updatedWidth > maxImageWidth || updatedHeight > maxImageHeight) {
                      flags.postImageResolutionLimit = id
                      image[code].hasResolutionError = true
                    }
                  }
                } else {
                  delete image[code].hasResolutionError
                  errors.mediaUploadingError = { isMediaUploading: true, mediaType: IMAGE }
                }
              }
            }

            image[code].hasExtensionError = false
            if (imageExtensions && extension && !image_processing && !imageExtensions.includes(`.${extension}`)) {
              flags.postImageExtensionError = id
              image[code].hasExtensionError = true
            }

            image[code].hasSizeError = false
            if (!image_processing && maxImageSize && size > maxImageSize) {
              flags.postImageSizeLimit = id
              image[code].hasSizeError = true
            }
          }

          if (aspect_ratio && media_aspect_ratios) {
            media_aspect_ratios.add(aspect_ratio)
          }
        }
      }
    }

    const checkPostForErrorVideosHelper = ({
      postComponent,
      postVideos,
      code,
      data,
      maxVideos,
      minVideoAspectRatio,
      maxVideoAspectRatio,
      minVideoDuration,
      maxVideoDuration,
      minReelDuration,
      maxReelDuration,
      maxVideoSize,
      videoExtensions,
      flags,
      warning_flags,
      hasAutoChangedPostComponent,
      media_aspect_ratios,
      postCustomization,
      videoEnding,
      minReelWidth,
      minReelHeight,
    }) => {
      if ((postComponent === POST_VIDEO || postComponent === POST_MIXED_MEDIA) && postVideos.length !== 0) {
        const { media_type, publish_as } = postCustomization

        const post_type = media_type || publish_as

        const limitedPostVideos = postVideos.length > maxVideos ? postVideos.slice(0, maxVideos) : postVideos
        for (const video of limitedPostVideos) {
          const { id, isNew, aspect_ratio, duration, size, extension, width: video_width, height: video_height } = video

          video[code] = {}

          if (!isNew) {
            let updatedAspectRatio = aspect_ratio ? Number(aspect_ratio).toFixed(2) : 0
            let updatedDuration = duration

            if (!updatedAspectRatio || !updatedDuration) {
              let element = document.getElementById(
                `${PREVIEW_MEDIA_CLASS_NAME}${data && data.id ? `-${data.id}` : ''}-${id}`
              )

              if (!element) {
                element = document.getElementById(`${POST_MEDIA_CLASS_NAME}-${id}`)
              }

              if (element) {
                const { children: { 0: videoElement } = [] } = element

                const { videoHeight, videoWidth, duration: videoDuration } = videoElement || element || {}

                if (videoWidth && videoHeight) {
                  updatedAspectRatio = Number((videoWidth / videoHeight).toFixed(2))
                  video.aspect_ratio = updatedAspectRatio
                }
                if (videoDuration) {
                  updatedDuration = videoDuration
                  video.duration = videoDuration
                }
              }
            }

            if (minVideoAspectRatio && maxVideoAspectRatio) {
              if (typeof video[code].hasAspectRatioError === 'boolean' && video[code].hasAspectRatioError) {
                if (code === INSTAGRAM && video_processing) {
                  if (post_type !== PUBLISH_AS_REELS) {
                    warning_flags.postVideoAspectRatioLimit = id
                  }
                } else {
                  flags.postVideoAspectRatioLimit = id
                }
              } else if (typeof video[code].hasAspectRatioError !== 'boolean') {
                video[code].hasAspectRatioError = false

                if (updatedAspectRatio) {
                  if (updatedAspectRatio < minVideoAspectRatio || updatedAspectRatio > maxVideoAspectRatio) {
                    if (code === INSTAGRAM && video_processing) {
                      if (post_type !== PUBLISH_AS_REELS) {
                        warning_flags.postVideoAspectRatioLimit = id
                      }
                    } else {
                      flags.postVideoAspectRatioLimit = id
                    }
                    video[code].hasAspectRatioError = true
                  }
                } else {
                  delete video[code].hasAspectRatioError
                }
              }
            }

            let actualMinVideoDuration = minVideoDuration
            let actualMaxVideoDuration = maxVideoDuration
            let is_facebook_reels = false

            if (code === FACEBOOK) {
              if (post_type === PUBLISH_AS_REELS || post_type === PUBLISH_AS_STORY) {
                actualMinVideoDuration = minReelDuration
                actualMaxVideoDuration = maxReelDuration
                is_facebook_reels = true
              }
            }

            if (actualMinVideoDuration && actualMaxVideoDuration) {
              if (typeof video[code].hasDurationError === 'boolean' && video[code].hasDurationError) {
                flags.postVideoDurationLimit = id
              } else if (typeof video[code].hasDurationError !== 'boolean') {
                video[code].hasDurationError = false

                if (updatedDuration) {
                  if (updatedDuration < actualMinVideoDuration) {
                    flags.postVideoDurationLimit = id
                    video[code].hasDurationError = true
                  } else if (updatedDuration > actualMaxVideoDuration) {
                    if (
                      video_processing &&
                      (code === TWITTER || code === SNAPCHAT || code === INSTAGRAM || is_facebook_reels)
                    ) {
                      warning_flags.postVideoDurationLimit = true
                    } else {
                      flags.postVideoDurationLimit = id
                      video[code].hasDurationError = true
                    }
                  }
                } else {
                  delete video[code].hasDurationError
                }
              }
            }

            video[code].hasExtensionError = false
            if (
              !hasAutoChangedPostComponent &&
              videoExtensions &&
              extension &&
              !videoExtensions.includes(`.${extension}`)
            ) {
              flags.postVideoExtensionError = id
              video[code].hasExtensionError = true
            }

            video[code].hasSizeError = false

            let actualVideoSize = maxVideoSize
            if (video_processing && videoEnding && video.network_sizes) {
              if (post_type && videoEnding[post_type] && video.network_sizes[videoEnding[post_type]]) {
                actualVideoSize = video.network_sizes[videoEnding[videoEnding[post_type]]]
              } else if (videoEnding.default && video.network_sizes[videoEnding.default]) {
                actualVideoSize = video.network_sizes[videoEnding.default]
              }
            }

            if (actualVideoSize && size > actualVideoSize && !video_processing) {
              flags.postVideoSizeLimit = id
              video[code].hasSizeError = true
            }
          }

          if (code === FACEBOOK && (post_type === PUBLISH_AS_REELS || post_type === PUBLISH_AS_STORY)) {
            if (minReelWidth && video_width && minReelWidth > video_width) {
              flags.postVideoResolutionLimit = id
              video[code].hasResolutionError = true
            } else if (minReelHeight && video_height && video_height < minReelHeight) {
              flags.postVideoResolutionLimit = id
              video[code].hasResolutionError = true
            }
          }

          if (aspect_ratio && media_aspect_ratios) {
            media_aspect_ratios.add(aspect_ratio)
          }
        }
      }
    }

    const checkPostForErrorDocumentsHelper = ({
      postComponent,
      postDocuments,
      code,
      maxDocuments,
      maxDocumentSize,
      documentExtensions,
      hasAutoChangedPostComponent,
      flags,
    }) => {
      if (postComponent === POST_DOCUMENT && postDocuments.length !== 0) {
        if (code === LINKEDIN) {
          const limitedPostDocuments =
            postDocuments.length > maxDocuments ? postDocuments.slice(0, maxDocuments) : postDocuments
          for (const document of limitedPostDocuments) {
            const { id, isNew, size, extension, title, description } = document
            document[code] = {}

            if (!isNew) {
              document[code].hasExtensionError = false
              if (
                !hasAutoChangedPostComponent &&
                documentExtensions &&
                extension &&
                !documentExtensions.includes(`.${extension}`)
              ) {
                flags.postDocumentExtensionError = id
                document[code].hasExtensionError = true
              }

              document[code].hasSizeError = false
              if (maxDocumentSize && size > maxDocumentSize) {
                flags.postDocumentSizeLimit = id
                document[code].hasSizeError = true
              }

              document[code].hasTitleLengthLimitError = false
              // if (!title) {
              //   flags.postDocumentTitleLengthLimit = id
              //   document[code].hasTitleLengthLimitError = true
              // }

              document[code].hasDescriptionLengthLimitError = false
              // if (!description) {
              //   flags.postDocumentDescriptionLengthLimit = id
              //   document[code].hasDescriptionLengthLimitError = true
              // }
            }
          }
        } else {
          flags.documentsNotSupported = true
        }
      }
    }

    const handleCheckPostForError = async (data) => {
      const errors = { networksErrors: {}, mediaUploadingError: { isMediaUploading: false, mediaType: '' } }
      const profiles = data?.selectedProfiles || selectedProfiles

      const whenToPublishTemp = data?.whenToPublishSpecial || whenToPublish

      for (const i in profiles) {
        const { code, id, scope = '' } = profiles[i]

        const profileType = getProfileFlavor({ profile: profiles[i] })

        const isTwitterProfileFreePlan = profileType === PROFILE_TYPE_TWITTER_FREE

        const networkLimit = NETWORK_LIMITS.find((limit) => limit.code === code)

        const {
          postComponent,
          postVideos = [],
          postImages = [],
          postDocuments = [],
          postMixedMedias = [],
          customizations = {},
          link,
        } = post

        const { picture_url: link_picture_url } = link || {}

        const { [code]: networkCustomizations } = customizations || {}
        const { [id]: profileCustomizations } = networkCustomizations || {}
        let {
          postComponent: customizedPostComponent = postComponent,
          postImages: customizedPostImages = postImages,
          postVideos: customizedPostVideos = postVideos,
          postDocuments: customizedPostDocuments = postDocuments,
          postMixedMedias: customizedPostMixedMedias = postMixedMedias,
        } = profileCustomizations || {}

        if (!errors.networksErrors[code]) {
          errors.networksErrors[code] = { hasErrors: false, code, profiles: { [id]: { hasErrors: false } } }
        }

        errors.networksErrors[code].profiles[id] = { hasErrors: false }

        const flags = {}
        const warning_flags = {}

        if (whenToPublishTemp === PUBLISH_DRAFT || whenToPublishTemp === DRAFT) {
          const { message } = profileCustomizations || {}

          const updatedPostText = message || postText

          if (
            updatedPostText.length === 0 &&
            (!customizedPostComponent ||
              (customizedPostComponent === POST_IMAGE && customizedPostImages.length === 0) ||
              (customizedPostComponent === POST_VIDEO && customizedPostVideos.length === 0) ||
              (customizedPostComponent === POST_LINK && picture_url.length === 0) ||
              (customizedPostComponent === POST_DOCUMENT && customizedPostDocuments.length === 0) ||
              (customizedPostComponent === POST_MIXED_MEDIA && customizedPostMixedMedias.length === 0))
          ) {
            flags.noDraftContent = true
          }
        } else if (networkLimit) {
          let hasAutoChangedPostComponent = false

          if (
            code === INSTAGRAM &&
            customizedPostImages.length !== 0 &&
            customizedPostComponent === POST_IMAGE &&
            customizedPostImages.length === customizedPostImages.filter(({ extension }) => extension === 'gif').length
          ) {
            customizedPostComponent = POST_VIDEO
            customizedPostVideos = customizedPostImages
            hasAutoChangedPostComponent = true
          }

          const {
            postTextMaxLimit,
            postTextMaxLimitPaid,
            commentTextMaxLimit,
            commentTextMaxLimitPaid,
            videoTitleLimit,
            titleLimit,
            maxHashTags,
            eventTitleMaxLimit,
            minReelAspectRatioVideoProcessingOff,
            maxReelAspectRatioVideoProcessingOff,
            minCarouselVideoAspectRatio,
            maxCarouselVideoAspectRatio,
            maxVideoDuration,
            minVideoDuration,
            minReelDuration,
            maxReelDuration,
            minImageWidth,
            minImageHeight,
            maxImageWidth,
            maxImageHeight,
            maxImageSize,
            maxVideoSize,
            maxDocumentSize,
            imageExtensions,
            videoExtensions,
            documentExtensions,
            maxImages,
            maxImagesInComments = 0,
            maxVideosInComments = 0,
            maxMixedMediasInComments = 0,
            maxVideos,
            maxDocuments = 0,
            minImages,
            slideshowMinImages,
            slideshowMaxImages,
            carouselMaxImages,
            maxTagsLength,
            spotlightDescriptionTextMaxLimit,
            savedStoryTitleTextMaxLimit,
            slugMaxLimit,
            maxTagsLimit,
            minMixedMediasInCarousel,
          } = networkLimit

          let { minVideoAspectRatio, maxVideoAspectRatio, minImageAspectRatio, maxImageAspectRatio } = networkLimit

          minImageAspectRatio = minImageAspectRatio
            ? Number(
                minImageAspectRatio
                  .split(':')
                  .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
                  .toFixed(2)
              )
            : null

          maxImageAspectRatio = maxImageAspectRatio
            ? Number(
                maxImageAspectRatio
                  .split(':')
                  .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
                  .toFixed(2)
              )
            : null

          minVideoAspectRatio = minVideoAspectRatio
            ? Number(
                minVideoAspectRatio
                  .split(':')
                  .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
                  .toFixed(2)
              )
            : null

          maxVideoAspectRatio = maxVideoAspectRatio
            ? Number(
                maxVideoAspectRatio
                  .split(':')
                  .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
                  .toFixed(2)
              )
            : null

          const postCustomization =
            post.customizations && post.customizations[code] ? post.customizations[code][id] || {} : {}

          const {
            comments = [],
            message = '',
            publish_as,
            media_type,
            device_gid,
            email_user_gid,
            tags = [],
            publishing_option,
          } = postCustomization

          if (customizedPostComponent === POST_MIXED_MEDIA) {
            customizedPostImages = []
            customizedPostVideos = []

            if (code === INSTAGRAM || code === THREADS) {
              if (code === INSTAGRAM) {
                if (customizedPostMixedMedias.length === 1) {
                  const { 0: { type } = {} } = customizedPostMixedMedias

                  if (type === IMAGE || type === IMAGE_DYNAMIC) {
                    customizedPostComponent = POST_IMAGE
                  } else if (type === VIDEO || type === VIDEO_DYNAMIC) {
                    customizedPostComponent = POST_VIDEO
                  }
                }
              }

              customizedPostMixedMedias.forEach((media) => {
                if (media.type) {
                  if (media.type.includes(IMAGE)) {
                    customizedPostImages.push(media)
                  }
                  if (media.type.includes(VIDEO)) {
                    customizedPostVideos.push(media)
                  }
                }
              })
            } else if (code === PINTEREST || code === REDDIT) {
              if (customizedPostMixedMedias[0] && customizedPostMixedMedias[0].type) {
                if (customizedPostMixedMedias[0].type.includes(IMAGE)) {
                  customizedPostImages.push(customizedPostMixedMedias[0])
                } else if (customizedPostMixedMedias[0].type.includes(VIDEO)) {
                  customizedPostVideos.push(customizedPostMixedMedias[0])
                }
              }
            } else if (code === YOUTUBE || code === VIMEO) {
              const foundMedia = customizedPostMixedMedias.find(({ type }) => type.includes(VIDEO))
              if (foundMedia) {
                customizedPostVideos.push(foundMedia)
              }
            } else if (code === VISTAPAGE || code === GOOGLE) {
              const foundMedia = customizedPostMixedMedias.find(({ type }) => type.includes(IMAGE))
              if (foundMedia) {
                customizedPostImages.push(foundMedia)
              }
            } else if (
              code === FACEBOOK ||
              code === LINKEDIN ||
              code === TIKTOK ||
              code === TWITTER ||
              code === TUMBLR ||
              code === SNAPCHAT ||
              code === BLUESKY ||
              code === CUSTOM
            ) {
              if (customizedPostMixedMedias[0] && customizedPostMixedMedias[0].type) {
                if (customizedPostMixedMedias[0].type.includes(IMAGE)) {
                  customizedPostImages = customizedPostMixedMedias.filter(({ type }) => type && type.includes(IMAGE))
                } else if (customizedPostMixedMedias[0].type.includes(VIDEO)) {
                  customizedPostVideos.push(customizedPostMixedMedias[0])
                }
              }
            }
          }

          if (code === INSTAGRAM) {
            if (media_type === PUBLISH_AS_REELS || media_type === PUBLISH_AS_STORY) {
              if (minReelAspectRatioVideoProcessingOff) {
                minVideoAspectRatio = Number(
                  minReelAspectRatioVideoProcessingOff
                    .split(':')
                    .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
                    .toFixed(2)
                )
              }

              if (maxReelAspectRatioVideoProcessingOff) {
                maxVideoAspectRatio = Number(
                  maxReelAspectRatioVideoProcessingOff
                    .split(':')
                    .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
                    .toFixed(2)
                )
              }
            } else if (media_type === PUBLISH_AS_CAROUSEL) {
              if (minCarouselVideoAspectRatio) {
                minVideoAspectRatio = Number(
                  minCarouselVideoAspectRatio
                    .split(':')
                    .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
                    .toFixed(2)
                )
              }

              if (maxCarouselVideoAspectRatio) {
                maxVideoAspectRatio = Number(
                  maxCarouselVideoAspectRatio
                    .split(':')
                    .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
                    .toFixed(2)
                )
              }
            }
          }

          const { variableTransformedText, notFoundVariables } = variableTransformer({
            str: message || postText,
            savedVariables,
            post,
            profileId: id,
          })

          if (notFoundVariables.length > 0) {
            flags.notFoundVariables = true
          }

          let updatedPostText = mentionsTransformer({
            body: variableTransformedText,
            color: COLOR_CONSTANTS.BLACK,
            fontWeight: 400,
            agencyMentions: agencyMentionsOptions.data,
            network: code,
          }).updatedTextTransformedMentions

          let symbolsAllowedLength = postTextMaxLimit - updatedPostText.length

          if (code === TWITTER) {
            if (post.link && post.link.url && post.link.active) {
              if (!updatedPostText.includes(post.link.url)) {
                const hasSpaceAtTheEnd = updatedPostText.charAt(updatedPostText.length - 1) === ' '
                updatedPostText = `${updatedPostText}${hasSpaceAtTheEnd ? '' : ' '}${post.link.url}`
              }
            }

            const updatedPostTextLength = twitter.getTweetLength(updatedPostText)

            symbolsAllowedLength =
              (isTwitterProfileFreePlan ? postTextMaxLimit : postTextMaxLimitPaid) - updatedPostTextLength
          } else if (code === PINTEREST) {
            symbolsAllowedLength =
              postTextMaxLimit -
              updatedPostText
                .split('\n')
                .map((line) => {
                  return line
                    .split(' ')
                    .filter((word) => !word.match(URL_REGEXP))
                    .join(' ')
                })
                .join('\n').length
          } else if (code === BLUESKY) {
            const { length: bsk_length, warning: bsk_warn } = blueskyGetPostLength({
              text: updatedPostText,
              post,
              user,
              profile: profiles[i],
            })
            symbolsAllowedLength = postTextMaxLimit - bsk_length

            if (bsk_warn) {
              warning_flags.shortnerActivate = id
            }
          }

          if (postTextMaxLimit && symbolsAllowedLength < 0) {
            flags.postTextMaxLimit = true
          }

          let has_not_empty_comment = false

          for (let i = 0; i < comments.length; i++) {
            let hasCommentMessageTextLengthError = false

            if (code === TWITTER && !isTwitterProfileFreePlan) {
              if (comments[i].message.length > commentTextMaxLimitPaid) {
                hasCommentMessageTextLengthError = true
              }
            } else if (comments[i].message.length > commentTextMaxLimit) {
              hasCommentMessageTextLengthError = true
            }

            if (hasCommentMessageTextLengthError) {
              flags.commentTextMaxLimit = i + 1
              break
            } else if (!flags.notFoundVariables) {
              const { notFoundVariables } = variableTransformer({
                str: comments[i].message,
                savedVariables,
                post,
                profileId: id,
              })

              if (notFoundVariables.length > 0) {
                flags.notFoundVariables = true
                break
              } else if (code === INSTAGRAM) {
                const hashtagsComment = twitter.extractHashtags(comments[i].message)

                if (hashtagsComment.length > maxHashTags) {
                  flags.hashtagsCommentLengthLimit = i + 1
                }
              }
            }

            let commentsPostImages = comments[i].postImages
            let commentsPostVideos = comments[i].postVideos

            if (comments[i].postMixedMedias && comments[i].postComponent === POST_MIXED_MEDIA) {
              commentsPostImages = []
              commentsPostVideos = []

              if (code === THREADS) {
                comments[i].postMixedMedias.forEach((media) => {
                  if (media.type) {
                    if (media.type.includes(IMAGE)) {
                      commentsPostImages.push(media)
                    }
                    if (media.type.includes(VIDEO)) {
                      commentsPostVideos.push(media)
                    }
                  }
                })
              }
            }

            if (commentsPostImages) {
              checkPostForErrorImagesHelper({
                postComponent: comments[i].postComponent,
                postImages: commentsPostImages,
                code,
                data: comments[i],
                maxImages: maxImagesInComments,
                minImageAspectRatio,
                maxImageAspectRatio,
                minImageHeight,
                minImageWidth,
                maxImageHeight,
                maxImageWidth,
                maxImageSize,
                imageExtensions,
                flags,
                warning_flags,
                errors,
                media_type,
              })
            }

            if (commentsPostVideos) {
              checkPostForErrorVideosHelper({
                postComponent: comments[i].postComponent,
                postVideos: commentsPostVideos,
                code,
                data: comments[i],
                maxVideos: maxVideosInComments,
                minVideoAspectRatio,
                maxVideoAspectRatio,
                minVideoDuration,
                maxVideoDuration,
                minReelDuration,
                maxReelDuration,
                maxVideoSize,
                videoExtensions,
                flags,
                warning_flags,
                postCustomization,
                videoEnding: networkLimit.videoEnding,
                minReelWidth: networkLimit.minReelWidth,
                minReelHeight: networkLimit.minReelHeight,
              })
            }

            if (
              comments[i].message.length !== 0 ||
              (comments[i].postComponent &&
                ((commentsPostImages && commentsPostImages.length !== 0) ||
                  (commentsPostVideos && commentsPostVideos.length > 0)))
            ) {
              has_not_empty_comment = true
            }
          }

          const media_aspect_ratios = new Set()

          checkPostForErrorImagesHelper({
            postComponent: customizedPostComponent,
            postImages: customizedPostImages,
            code,
            data: post,
            maxImages,
            minImageAspectRatio,
            maxImageAspectRatio,
            minImageHeight,
            minImageWidth,
            maxImageHeight,
            maxImageWidth,
            maxImageSize,
            imageExtensions,
            flags,
            warning_flags,
            errors,
            media_aspect_ratios,
            media_type,
          })

          checkPostForErrorVideosHelper({
            postComponent: customizedPostComponent,
            postVideos: customizedPostVideos,
            code,
            data: post,
            maxVideos,
            minVideoAspectRatio,
            maxVideoAspectRatio,
            minVideoDuration,
            maxVideoDuration,
            minReelDuration,
            maxReelDuration,
            maxVideoSize,
            videoExtensions,
            flags,
            warning_flags,
            hasAutoChangedPostComponent,
            media_aspect_ratios,
            postCustomization,
            videoEnding: networkLimit.videoEnding,
            minReelWidth: networkLimit.minReelWidth,
            minReelHeight: networkLimit.minReelHeight,
          })

          checkPostForErrorDocumentsHelper({
            postComponent: customizedPostComponent,
            postDocuments: customizedPostDocuments,
            code,
            maxDocuments,
            maxDocumentSize,
            documentExtensions,
            hasAutoChangedPostComponent,
            flags,
          })

          if (code === FACEBOOK || code === TWITTER || code === THREADS || code === BLUESKY) {
            if (
              updatedPostText.length === 0 &&
              (!customizedPostComponent ||
                (customizedPostComponent === POST_IMAGE && customizedPostImages.length === 0) ||
                (customizedPostComponent === POST_VIDEO && customizedPostVideos.length === 0) ||
                (customizedPostComponent === POST_LINK && picture_url.length === 0) ||
                (customizedPostComponent === POST_MIXED_MEDIA && customizedPostMixedMedias.length === 0))
            ) {
              if (customizedPostComponent === POST_LINK && picture_url.length === 0) {
                flags.postLinkPictureUrlLengthLimit = true
              } else {
                flags.noContent = true
              }
            }
          }

          if (code === LINKEDIN || code === CUSTOM) {
            if (
              updatedPostText.length === 0 &&
              (!customizedPostComponent ||
                (customizedPostComponent === POST_IMAGE && customizedPostImages.length === 0) ||
                (customizedPostComponent === POST_VIDEO && customizedPostVideos.length === 0) ||
                (customizedPostComponent === POST_LINK && picture_url.length === 0) ||
                (customizedPostComponent === POST_DOCUMENT && customizedPostDocuments.length === 0) ||
                (customizedPostComponent === POST_MIXED_MEDIA && customizedPostMixedMedias.length === 0))
            ) {
              if (customizedPostComponent === POST_LINK && picture_url.length === 0) {
                flags.postLinkPictureUrlLengthLimit = true
              } else {
                flags.noContent = true
              }
            }
          }

          if (code === REDDIT) {
            if (updatedPostText.length === 0) {
              flags.postTextMinLimit = true
            }

            const { title = '', subreddit = '' } = postCustomization
            if (title.length > titleLimit) {
              flags.titleLengthLimit = true
            }
            if (subreddit.length === 0) {
              flags.subredditLength = true
            } else if (subreddit.length > titleLimit) {
              flags.subredditLengthLimit = true
            }
          }

          if (code === YOUTUBE || code === VIMEO) {
            const { video_title = '' } = postCustomization
            if (video_title.length === 0) {
              flags.videoTitleLength = true
            } else if (video_title.length > videoTitleLimit) {
              flags.videoTitleLengthLimit = true
            }
            if (
              (customizedPostComponent !== POST_VIDEO && customizedPostComponent !== POST_MIXED_MEDIA) ||
              customizedPostVideos.length === 0
            ) {
              flags.postVideosLengthLimit = true
            }

            if (code === YOUTUBE) {
              let tagsLength = 0
              tags.forEach((tag) => {
                if (tag.includes(' ')) {
                  tagsLength += 2
                }
                tagsLength += tag.length
              })
              if (tagsLength > maxTagsLength) {
                flags.tagsTextMaxLimit = true
              }
            }
          }

          if (code === GOOGLE) {
            const { event_title = '', post_type, call_to_action } = postCustomization

            if (event_title.length > eventTitleMaxLimit) {
              flags.eventTitleMaxLimit = true
            }

            const hasMedia =
              customizedPostComponent &&
              ((customizedPostComponent === POST_IMAGE && customizedPostImages.length !== 0) ||
                (customizedPostComponent === POST_LINK && picture_url.length !== 0) ||
                (customizedPostComponent === POST_MIXED_MEDIA && customizedPostImages.length !== 0))

            if (updatedPostText.length === 0 && !hasMedia) {
              if (customizedPostComponent === POST_LINK && picture_url.length === 0) {
                flags.postLinkPictureUrlLengthLimit = true
              } else {
                flags.noContent = true
              }
            }

            if (post_type === 'OFFER') {
              if (!postCustomization?.offer?.coupon) {
                flags.postOfferCoupon = true
              } else if (!postCustomization?.offer?.terms) {
                flags.postOfferTerms = true
              }
            }

            if (post_type === 'EVENT' || post_type === 'OFFER') {
              if (!postCustomization.event_title) {
                flags.postEventTitle = true
              } else if (!postCustomization.event_start) {
                flags.postEventStart = true
              } else if (!postCustomization.event_end) {
                flags.postEventEnd = true
              }
            }

            if (call_to_action && call_to_action !== 'CALL' && (!post.link || !post.link.present)) {
              flags.invalidCTA = true
            }
          }

          if (code === THREADS) {
            const { tagged_countries = [], hide_replies = false } = postCustomization

            if (customizedPostImages.length > maxImages) {
              warning_flags.postImagesLengthLimit = id
            }

            if (scope.indexOf('geo,') === -1) {
              if (tagged_countries.length > 0) {
                flags.countryTargetingIsNotAvailable = id
              }
            }

            if (hide_replies && has_not_empty_comment) {
              warning_flags.threadRepliesAreHidden = true
            }
          }

          if (code === TIKTOK) {
            if (customizedPostImages.length > maxImages) {
              warning_flags.postImagesLengthLimit = id
            }
            if (customizedPostComponent === POST_IMAGE) {
              if (customizedPostImages.length === 0) {
                flags.postImagesLengthLimit = true
              }
            } else if (customizedPostComponent === POST_VIDEO) {
              if (customizedPostVideos.length === 0) {
                flags.postVideosLengthLimit = true
              }
            } else if (customizedPostComponent === POST_MIXED_MEDIA) {
              if (customizedPostImages.length === 0 && customizedPostVideos.length === 0) {
                flags.postMixedMediasLengthLimit = true
              } else if (customizedPostImages.length) {
                if (customizedPostImages.length < minImages) {
                  flags.postImagesMinLengthLimit = true
                }
              }
            } else if (customizedPostComponent === POST_LINK) {
              if (!link_picture_url) {
                flags.postImagesLengthLimit = true
              }
            } else {
              flags.noContent = true
            }
          }

          if (code === INSTAGRAM) {
            const hashtagsText = twitter.extractHashtags(updatedPostText)

            if (hashtagsText.length > maxHashTags) {
              flags.hashtagsTextLengthLimit = true
            }

            if (customizedPostComponent === POST_IMAGE) {
              if (customizedPostImages.length === 0) {
                warning_flags.postImagesLengthLimit = true
              }
            } else if (customizedPostComponent === POST_VIDEO) {
              if (customizedPostVideos.length === 0) {
                flags.postVideosLengthLimit = true
              }
            } else if (customizedPostComponent === POST_LINK) {
              if (picture_url.length === 0) {
                flags.postLinkPictureUrlLengthLimit = true
              }
            } else if (customizedPostComponent === POST_MIXED_MEDIA) {
              if (customizedPostMixedMedias.length < minMixedMediasInCarousel) {
                flags.postMixedMediasLengthLimit = true
              }
            } else if (![POST_VIDEO, POST_IMAGE, POST_LINK, POST_MIXED_MEDIA].includes(customizedPostComponent)) {
              flags.noContent = true
            }

            if (customizedPostComponent === POST_VIDEO || customizedPostComponent === POST_MIXED_MEDIA) {
              if (media_aspect_ratios.size > 1) {
                warning_flags.postMediaAspectRatioVariousWarning = true
              }
            }
          }

          if (code === PINTEREST) {
            const { title = '', board_id, link: link_customization } = postCustomization

            if (title.length > titleLimit) {
              flags.titleLengthLimit = true
            }

            if (!board_id) {
              flags.noBoardSelected = true
            }

            let link_customization_updated = link_customization

            if (
              !link_customization_updated &&
              customizedPostComponent === POST_LINK &&
              url &&
              typeof link_customization !== 'string'
            ) {
              link_customization_updated = url
            }

            if (link_customization_updated) {
              try {
                if (link_customization_updated.indexOf('http') !== 0) {
                  link_customization_updated = `https://${link_customization_updated}`
                }

                const link_customization_updated_res = link_customization_updated.match(URL_REGEXP)

                if (!link_customization_updated_res) {
                  flags.postPinLinkError = true
                }
              } catch (e) {
                flags.postPinLinkError = true
              }
            }

            if (customizedPostComponent === POST_IMAGE) {
              if (customizedPostImages.length === 0) {
                flags.postImagesLengthLimit = true
              }
            } else if (customizedPostComponent === POST_VIDEO) {
              if (customizedPostVideos.length === 0) {
                flags.postVideosLengthLimit = true
              }
            } else if (customizedPostComponent === POST_LINK) {
              if (picture_url.length === 0) {
                flags.postLinkPictureUrlLengthLimit = true
              }
            } else if (customizedPostComponent === POST_MIXED_MEDIA) {
              if (customizedPostMixedMedias.length === 0) {
                flags.postMixedMediasLengthLimit = true
              }
            } else if (![POST_IMAGE, POST_VIDEO, POST_LINK, POST_MIXED_MEDIA].includes(customizedPostComponent)) {
              flags.noContent = true
            }
          }

          if (code === FACEBOOK) {
            if (
              publish_as === PUBLISH_AS_SLIDESHOW &&
              (customizedPostImages.length < slideshowMinImages || customizedPostImages.length > slideshowMaxImages)
            ) {
              flags.postSlideshowLimit = true
            } else if (publish_as === PUBLISH_AS_CAROUSEL && customizedPostImages.length > carouselMaxImages) {
              warning_flags.postCarouselLimit = id
            } else if (publish_as === PUBLISH_AS_CAROUSEL) {
              if (!post.link || !post.link.present) {
                const words = updatedPostText
                  ? updatedPostText
                      .split('\n')
                      .join(' ')
                      .split(' ')
                  : []

                const foundLink = words.find((word) => word.match(URL_REGEXP) !== null)

                if (!foundLink) {
                  flags.carouselLinkLengthLimit = true
                }
              }
            }
          }

          if (code === SNAPCHAT) {
            if (updatedPostText.length === 0) {
              flags.postTextMinLimit = true
            }

            if (publish_as === PUBLISH_AS_SPOTLIGHT) {
              const { description = '' } = postCustomization

              if (description.length > spotlightDescriptionTextMaxLimit) {
                flags.spotlightDescriptionTextLengthLimit = true
              }
            } else if (publish_as === PUBLISH_AS_SAVED_STORY) {
              const { title: saved_story_title = '' } = postCustomization

              if (saved_story_title.length > savedStoryTitleTextMaxLimit) {
                flags.savedStoryTitleTextLengthLimit = true
              }
            }

            if (customizedPostComponent === POST_IMAGE) {
              if (customizedPostImages.length === 0) {
                flags.postImagesLengthLimit = true
              }
            } else if (customizedPostComponent === POST_VIDEO) {
              if (customizedPostVideos.length === 0) {
                flags.postVideosLengthLimit = true
              }
            } else if (customizedPostComponent === POST_MIXED_MEDIA) {
              if (customizedPostMixedMedias.length === 0) {
                flags.postMixedMediasLengthLimit = true
              }
            } else if (customizedPostComponent === POST_LINK) {
              if (!link_picture_url) {
                flags.postImagesLengthLimit = true
              }
            } else if (![POST_IMAGE, POST_VIDEO, POST_MIXED_MEDIA, POST_LINK].includes(customizedPostComponent)) {
              flags.noContent = true
            }
          }

          if (code === VISTAPAGE) {
            const hasMedia =
              customizedPostComponent &&
              ((customizedPostComponent === POST_IMAGE && customizedPostImages.length !== 0) ||
                (customizedPostComponent === POST_LINK && picture_url.length !== 0) ||
                (customizedPostComponent === POST_MIXED_MEDIA && customizedPostImages.length !== 0))

            if (updatedPostText.length === 0 && !hasMedia) {
              if (customizedPostComponent === POST_LINK && picture_url.length === 0) {
                flags.postLinkPictureUrlLengthLimit = true
              } else {
                flags.noContent = true
              }
            }
          }

          if (code === TUMBLR) {
            if (
              updatedPostText.length === 0 &&
              (!customizedPostComponent ||
                (customizedPostComponent === POST_IMAGE && customizedPostImages.length === 0) ||
                (customizedPostComponent === POST_VIDEO && customizedPostVideos.length === 0) ||
                (customizedPostComponent === POST_LINK && url.length === 0) ||
                (customizedPostComponent === POST_MIXED_MEDIA && customizedPostMixedMedias.length === 0))
            ) {
              flags.noContent = true
            }

            const { title = '', slug = '', tags = [] } = postCustomization

            if (title.length > titleLimit) {
              flags.titleLengthLimit = true
            }

            if (slug.length > slugMaxLimit) {
              flags.slugLengthLimit = true
            }

            if (tags.length > maxTagsLimit) {
              flags.tagsMaxLimit = true
            }
          }

          if (publishing_option === PUBLISHING_OPTION_NOTIFICATION && !device_gid && !email_user_gid) {
            flags.notificationReminderLengthLimit = true
          }

          errors.networksErrors[code].profiles[id].warning_flags = warning_flags
        }

        if (checkForErrors || whenToPublishTemp === PUBLISH_DRAFT) {
          errors.networksErrors[code].profiles[id].flags = flags
          for (const i in flags) {
            if (flags[i]) {
              errors.networksErrors[code].hasErrors = true
              errors.networksErrors[code].profiles[id].hasErrors = true
            }
          }
        }

        const keys = [
          { key: 'customizedPostImages', keyPostComponent: POST_IMAGE, mediaType: IMAGE },
          { key: 'customizedPostVideos', keyPostComponent: POST_VIDEO, mediaType: VIDEO },
          { key: 'customizedPostDocuments', keyPostComponent: POST_DOCUMENT, mediaType: DOCUMENT },
          { key: 'customizedPostMixedMedias', keyPostComponent: POST_MIXED_MEDIA, mediaType: 'media' },
        ]

        for (let i = 0; i < keys.length; i++) {
          if (errors.mediaUploadingError.isMediaUploading) {
            break
          }

          const { key, keyPostComponent, mediaType } = keys[i]

          if (customizedPostComponent === keyPostComponent) {
            if (profileCustomizations && profileCustomizations[key]) {
              if (profileCustomizations[key].find(({ isNew }) => isNew)) {
                errors.mediaUploadingError = { isMediaUploading: true, mediaType }
              }
            }

            break
          }
        }
      }

      const keys = [
        { key: 'postImages', keyPostComponent: POST_IMAGE, mediaType: IMAGE },
        { key: 'postVideos', keyPostComponent: POST_VIDEO, mediaType: VIDEO },
        { key: 'postDocuments', keyPostComponent: POST_DOCUMENT, mediaType: DOCUMENT },
        { key: 'postMixedMedias', keyPostComponent: POST_MIXED_MEDIA, mediaType: 'media' },
      ]

      for (let i = 0; i < keys.length; i++) {
        if (errors.mediaUploadingError.isMediaUploading) {
          break
        }

        const { key, keyPostComponent, mediaType } = keys[i]

        if (postComponent === keyPostComponent) {
          if (post[key]) {
            if (post[key].find(({ isNew }) => isNew)) {
              errors.mediaUploadingError = { isMediaUploading: true, mediaType }
            }
          }

          break
        }
      }

      if (!errors.mediaUploadingError.isMediaUploading && postComponent === POST_LINK && link && isUploadingLinkImage) {
        errors.mediaUploadingError = { isMediaUploading: true, mediaType: IMAGE }
      }

      post.errors = errors
      setPostErrors({ ...errors })
      return { post }
    }

    const getMentions = async () => {
      if (!post.agencyMentions) {
        let response = []
        try {
          setAgencyMentionsOptions({ ...{ isLoading: true, data: [] } })

          response = await request({
            method: 'GET',
            path: ROUTE_MENTIONS,
          })

          if (!response || response.error) {
            response = []
          }
        } catch (error) {
          errorHelper({ error, componentName: PostCreationComponent.displayName, functionName: 'getMentions' })
        } finally {
          setAgencyMentionsOptions({ ...{ isLoading: false, data: response } })
        }
      } else {
        setAgencyMentionsOptions({ ...{ isLoading: false, data: post.agencyMentions } })
      }
    }

    const getTrackingTypes = async () => {
      if (agencyTrackingTypes.length === 0) {
        try {
          const response = await request({
            method: 'GET',
            path: `${ROUTE_TRACKING}/types`,
          })
          if (response && !response.error) {
            const { trackingTypes } = response
            setAgencyTrackingTypes([...trackingTypes])
          }
        } catch (error) {
          errorHelper({ error, componentName: PostCreationComponent.displayName, functionName: 'getTrackingTypes' })
        }
      }
    }

    const getIntro = async () => {
      if (Object.keys(intro).length === 0) {
        try {
          const response = await request({
            method: 'GET',
            path: 'me/intro',
          })
          if (response && !response.error) {
            setIntro({ ...response })
          }
        } catch (error) {
          errorHelper({ error, componentName: PostCreationComponent.displayName, functionName: 'getIntro' })
        }
      }
    }

    const getUserReminderNotificationsList = async () => {
      if (Object.keys(reminderNotificationsListByEntity).length === 0) {
        const reminderNotificationsList = await getReminderNotificationsList({ entityIds: [] })

        setReminderNotificationsListByEntity({ ...reminderNotificationsList })
      }
    }

    const getSavedVariables = async ({ profiles = [] }) => {
      try {
        const response = await request({
          path: `${ROUTE_VARIABLE}?profiles=${profiles.map(({ id }) => id).join(',')}`,
        })
        const { error, list = [], profile_variables } = response || {}
        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          setSavedVariables(list)
          if (profiles && profiles.length !== 0) {
            setProfileVariables({ ...profile_variables })
          }
        }
      } catch (error) {
        errorHelper({ error, componentName: PostCreationComponent.displayName, functionName: 'getSavedVariables' })
      }
    }

    const getProcessingMediasUrlEndings = async ({ media_gids }) => {
      try {
        const response = await request({
          path: `${ROUTE_MEDIA}/processed-urls`,
          method: 'POST',
          body: { medias: media_gids },
        })
        const { medias = [] } = response || {}

        if (medias && medias.length !== 0) {
          postDispatch({
            type: 'processed_urls',
            data: { medias },
          })
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: PostCreationComponent.displayName,
          functionName: 'getProcessingMediasUrlEndings',
          showAlert: false,
        })
      }
    }

    const getBoosts = async ({ forceUpdate = false }) => {
      if (!isAdvocacy && (!post.boosts_options || forceUpdate)) {
        let boosts = []
        try {
          const response = await request({ path: `${ROUTE_BOOST_CONFIGURATION}?include_configuration=true` })

          const { error, data } = response || {}

          if (response && !error) {
            boosts = data
          }
        } catch (error) {
          errorHelper({ error, componentName: PostCreationComponent.displayName, functionName: 'getBoosts' })
        } finally {
          setBoostsOptions({ ...{ data: boosts } })
        }
      }
    }

    const getBoostsListenerFunction = () => {
      getBoosts({ forceUpdate: true })
    }

    useEffect(() => {
      document.addEventListener('mousedown', handleMouseClick, false)

      window.addEventListener('handleUpdateBoosts', getBoostsListenerFunction)

      return () => {
        document.removeEventListener('mousedown', handleMouseClick, false)
        window.removeEventListener('handleUpdateBoosts', getBoostsListenerFunction)
      }
    }, [handleMouseClick])

    useEffect(() => {
      if (agencyMentionsOptions.data.length) {
        postDispatch({
          type: 'agency_mentions',
          data: { agencyMentions: agencyMentionsOptions.data },
        })
      }

      checkIfComposerDataIsLoading()
    }, [agencyMentionsOptions])

    useEffect(() => {
      if (agencyMentionsSkipped.length) {
        postDispatch({
          type: 'agency_mentions_skipped',
          data: { agencyMentionsSkipped },
        })
      }
    }, [agencyMentionsSkipped])

    useEffect(() => {
      if (profileVariables) {
        postDispatch({
          type: 'profile_variables',
          data: { profileVariables },
        })
      }
    }, [profileVariables])

    useEffect(() => {
      if (boostsOptions.data.length) {
        postDispatch({
          type: 'boost_options',
          data: { boost_options: boostsOptions.data },
        })
      }

      checkIfComposerDataIsLoading()
    }, [boostsOptions])

    useEffect(() => {
      const updatedPostForEdit = postForEdit || {}
      if (!updatedPostForEdit.customizations) {
        updatedPostForEdit.customizations = {
          video_processing:
            typeof user_settings_video_processing !== 'undefined' ? user_settings_video_processing : true,
          image_processing:
            typeof user_settings_image_processing !== 'undefined' ? user_settings_image_processing : true,
        }
      } else {
        const { video_processing, image_processing } = updatedPostForEdit.customizations

        if (typeof video_processing === 'undefined' || image_processing === 'undefined') {
          updatedPostForEdit.customizations.video_processing =
            typeof user_settings_video_processing !== 'undefined' ? user_settings_video_processing : true
          updatedPostForEdit.customizations.image_processing =
            typeof user_settings_image_processing !== 'undefined' ? user_settings_image_processing : true
        }
      }

      postDispatch({
        type: 'post',
        data: updatedPostForEdit,
      })
    }, [postForEdit])

    useEffect(() => {
      handleCheckPostForError()

      if (
        postText.length !== 0 ||
        postImages.length !== 0 ||
        postVideos.length !== 0 ||
        postDocuments.length !== 0 ||
        postMixedMedias.length !== 0 ||
        (url.length !== 0 && linkUrlStates.isLinkUrlCorrect)
      ) {
        if (!isPostFormDirty) {
          setIsPostFormDirty(true)
        }
      } else if (isPostFormDirty) {
        setIsPostFormDirty(false)
      }

      // eslint-disable-next-line no-use-before-define
      checkIfMediasHaveBeenProcessed()

      onChangeUpdatePost({ post })
    }, [post, whenToPublish])

    useEffect(() => {
      setSelectedProfiles([...props.selectedProfiles])
      handleCheckPostForError({ selectedProfiles: props.selectedProfiles })
      getSavedVariables({ profiles: props.selectedProfiles })
    }, [props.selectedProfiles])

    useEffect(() => {
      setFeaturesEnabled({
        ...checkIfPlanHasFeatureEnabled({
          user,
          features: [FEATURE_LINK_SHORTENER, FEATURE_UNIVERSAL_MENTIONS, FEATURE_UTM_TRACKING, FEATURE_ADD_TO_QUEUE],
        }),
      })

      if (agencyLabels.length === 0) {
        getLabels({ setLabels: setAgencyLabels, type: LABELS_TYPE_PUBLICATION })
      }

      getMentions()
      getTrackingTypes()
      getIntro()
      getUserReminderNotificationsList()
      getBoosts({})

      if (textAreaRef && textAreaRef.current) {
        textAreaRef.current.handleChangePostText(postForEdit.postText || '')
      }

      setTextVariantOffsets([...transformTextVariantsToUnicodeVariant()])

      const observer = new ResizeObserver(() => {
        const el = document.getElementById('ai-body-icon')
        if (el) {
          const { top, left } = el.getBoundingClientRect()
          const ai_prompt = document.getElementById('ai-prompt')
          if (ai_prompt) {
            ai_prompt.style.top = `${top + 40}px`
            ai_prompt.style.left = `${left + 3}px`
          }

          const ai_prompt_refine = document.getElementById('publish-modal-ai-prompt-refine')

          if (ai_prompt_refine) {
            ai_prompt_refine.style.top = `${top - 30}px`
            ai_prompt_refine.style.left = `${left + 5}px`
          }
        }
      })
      observer.observe(textAreaRef.current.getTextAreaCurrent())
      return () => {
        observer.disconnect()
      }
    }, [])

    const clearMediaProcessingInterval = ({ id, processed }) => {
      mediaProcessingIntervalsDispatch({ type: 'remove_interval', id })

      mediaProcessingIntervalsDispatch({
        type: 'change_interval',
        id,
        data: { processed },
      })
    }

    const getProcessedUrlsForCheckingVideoIfHasBeenProcessed = async ({
      user,
      id,
      updated_at_time,
      s3_urls,
      media,
    }) => {
      try {
        const { entity } = user
        const { id: root_entity_gid } = entity || {}

        if (mediaProcessingIntervals && mediaProcessingIntervals[id] && root_entity_gid) {
          const { counter = 0 } = mediaProcessingIntervals[id] || {}

          mediaProcessingIntervalsDispatch({
            type: 'change_interval',
            id,
            data: { counter: counter + 1 },
          })

          const responses = await Promise.all(
            s3_urls
              .filter((url) => {
                if (url.includes('.json')) {
                  return !media.aspect_ratio || !media.duration
                }

                return true
              })
              .map((url) => {
                if (url.includes('.json')) {
                  return fetch(url, {
                    method: 'GET',
                  })
                }

                return fetch(url, {
                  method: 'HEAD',
                })
              })
          )

          const foundBadResponse = responses.find((response) => !response || response.status !== 200)
          for (const response of responses) {
            if (response.status === 200) {
              if (response.url.includes('.json')) {
                try {
                  const data = await response.json()

                  if (data) {
                    const { width, height, duration, mime } = data

                    if (width && height) {
                      media.aspect_ratio = width / height
                      media.width = width
                      media.height = height
                    }

                    if (duration) {
                      try {
                        media.duration = Number(duration)
                      } catch (error) {
                        // duration is not a number, not logging this error
                      }
                    }

                    if (mime) {
                      const ext = mime.indexOf('/') > -1 ? mime.split('/')[1] : mime

                      if (VIDEO_MIME_TYPES[ext]) {
                        // eslint-disable-next-line prefer-destructuring
                        media.extension = VIDEO_MIME_TYPES[ext][0]
                      }
                    }
                  }
                } catch (error) {
                  // data is not a json object, not logging this error
                }
              } else {
                const size = response.headers.get('content-length')

                const start_index = response.url.indexOf(id) + id.length + 1
                const end_index = response.url.indexOf('.mp4')

                const sizes = media.network_sizes || {}
                sizes[response.url.substring(start_index, end_index)] = size
                media.network_sizes = sizes
              }
            }
          }

          if (!foundBadResponse) {
            clearMediaProcessingInterval({ id, processed: true })
          } else {
            const now = moment.utc().valueOf()

            if (
              mediaProcessingIntervals[id].counter === MAX_PROCESSED_URLS_CALLED_TIMES ||
              (updated_at_time && now - updated_at_time > 300000) // 5 minutes
            ) {
              clearMediaProcessingInterval({ id, processed: false })
            }
          }
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: `${PostCreationComponent.displayName} - MediaProcessingContext`,
          functionName: 'getProcessedUrlsForCheckingVideoIfHasBeenProcessed',
          showAlert: false,
        })
        clearMediaProcessingInterval({ id, processed: true })
      }
    }

    const checkIfMediaHasBeenProcessed = ({ media }) => {
      const { id, tempId, isNew, updated_at_time, s3_urls } = media

      if (!tempId && !isNew && id && !mediaProcessingIntervals[id] && s3_urls && s3_urls.length !== 0) {
        const interval_func = () => {
          getProcessedUrlsForCheckingVideoIfHasBeenProcessed({ user, id, updated_at_time, s3_urls, media })
        }

        mediaProcessingIntervalsDispatch({
          type: 'add_interval',
          id,
          data: {
            interval: setInterval(() => {
              interval_func()
            }, PROCESSED_URLS_CALLED_TIMEOUT),
            counter: 0,
            interval_func,
          },
        })
      }
    }

    const checkIfMediasHaveBeenProcessed = () => {
      // all video_processing logic checks are for only optimization. We do not need to look for s3 urls or process the medias if video_processing is off

      const medias_for_dehibernation = []
      const media_gids_for_getting_s3_urls = []
      const medias_for_update = new Set()

      const checkForHibernationAndS3Urls = ({ media }) => {
        const { id, tempId, isNew, hibernated, s3_urls } = media

        if (!tempId && !isNew && id) {
          if (hibernated) {
            medias_for_dehibernation.push(media)
            medias_for_update.add(id)
          }

          if (typeof s3_urls === 'undefined') {
            media_gids_for_getting_s3_urls.push(id)

            if (video_processing) {
              medias_for_update.add(id)
            }
          }
        }
      }

      const keys = ['postVideos', 'postMixedMedias']

      keys.forEach((key) => {
        if (post[key] && post[key].length !== 0) {
          post[key].forEach((media) => {
            if (media.type === VIDEO) {
              checkForHibernationAndS3Urls({ media })

              if (video_processing) {
                checkIfMediaHasBeenProcessed({ media })
              }
            }
          })
        }
      })

      for (let i = 0; i < selectedProfiles.length; i++) {
        const { id, code } = selectedProfiles[i]

        const { customizations } = post
        const { [code]: networkCustomizations } = customizations || {}
        const { [id]: profileCustomizations } = networkCustomizations || {}

        keys.forEach((key) => {
          if (profileCustomizations && profileCustomizations[key] && profileCustomizations[key].length) {
            profileCustomizations[key].forEach((media) => {
              if (media.type === VIDEO) {
                checkForHibernationAndS3Urls({ media })

                if (video_processing) {
                  checkIfMediaHasBeenProcessed({ media })
                }
              }
            })
          }
        })
      }

      if (medias_for_update.size > 0) {
        keys.forEach((key) => {
          postDispatch({
            type: 'update_medias_with_same_data',
            data: {
              selectedProfiles,
              post_media_key: key,
              media_gids: [...medias_for_update],
              media_data: { hibernated: false, s3_urls: video_processing ? [] : undefined },
              // media_data: { hibernated: false, s3_urls: [] },
            },
          })
        })

        if (medias_for_dehibernation.length !== 0) {
          deHibernateMedias({ medias: medias_for_dehibernation })
        }

        if (video_processing && media_gids_for_getting_s3_urls.length !== 0) {
          getProcessingMediasUrlEndings({ media_gids: media_gids_for_getting_s3_urls })
        }
      }
    }

    const handleClickSelectEmoji = (e) => {
      const current = textAreaRef.current.getTextAreaCurrent()
      const text = textAreaRef.current.getTextAreaValue()

      const cursorPosition = current.selectionStart

      let start = text.substring(0, cursorPosition)
      const end = text.substring(cursorPosition)

      const sym = e.unified.split('-')
      const codesArray = []
      sym.forEach((el) => codesArray.push(`0x${el}`))
      const emojiPic = String.fromCodePoint(...codesArray)

      start = `${start}${emojiPic}`
      textAreaRef.current.handleChangePostText(`${start}${end}`)
      current.selectionStart = start.length
    }

    const handleClickSelectPostTextHelperComponent = (component) => {
      if (showPostTextHelperComponent && showPostTextHelperComponent === component) {
        setShowPostTextHelperComponent(null)
      } else {
        setShowPostTextHelperComponent(component)
      }
    }

    const handleClickOpenVariablesModal = () => {
      setVariablesModalOptions({
        ...{
          isOpen: true,
          profiles: [],
          variables: savedVariables.map(({ label }) => ({ variable: `{{${label}}}`, for_edit: true })),
          needValidation: false,
        },
      })
    }

    const handleClickCloseVariablesModal = () => {
      setVariablesModalOptions({ ...VARIABLES_MODAL_OPTIONS })
    }

    const handleClickSaveUpdatedVariables = ({ variables, profiles, callbackFunction }) => {
      variables.forEach((variable) => {
        const foundSavedVariableIndex = savedVariables.findIndex(({ id }) => id === variable.id)
        if (foundSavedVariableIndex === -1) {
          savedVariables.push(variable)
        } else {
          savedVariables[foundSavedVariableIndex] = variable
        }
      })

      const { profile_variables: post_profile_variables = {} } = post

      profiles.forEach((profile) => {
        const { id, profile_variables } = profile
        for (const variable_id in profile_variables) {
          if (profile_variables.hasOwnProperty(variable_id)) {
            const { profileVariableValueId, value } = profile_variables[variable_id]

            if (profileVariableValueId) {
              //  if (post) {
              if (!post_profile_variables[id]) {
                post_profile_variables[id] = {}
              }
              if (!post_profile_variables[id][variable_id]) {
                post_profile_variables[id][variable_id] = {}
              }

              post_profile_variables[id][variable_id] = { value, id: profileVariableValueId }
            }
          }
        }
      })

      postDispatch({
        type: 'profile_variables',
        data: { profileVariables: post_profile_variables },
      })

      setSavedVariables([...savedVariables])

      handleClickCloseVariablesModal()

      if (callbackFunction) {
        callbackFunction()
      }
    }

    const handleClickOpenCompliancePreviewModal = () => {
      const { selectedEntitiesWithSelectedProfiles } = getSelectedProfileGroupsAndTimezone({
        user,
        selectedProfiles,
        selectedEntities,
      })

      if (selectedEntitiesWithSelectedProfiles.length === 0) {
        Alert.error('Please select at least one social profile.', { timeout: 5000 })
      } else {
        setCompliancePreviewModalOptions({
          ...{
            isOpen: true,
            entityIds: selectedEntitiesWithSelectedProfiles.map(({ id }) => id),
          },
        })
      }
    }

    const handleClickCloseCompliancePreviewModal = () => {
      setCompliancePreviewModalOptions({ ...DEFAULT_COMPLIANCE_PREVIEW_MODAL })
    }

    const handleClickAddTextToPostNewLine = ({ message, using_ai }) => {
      const current = textAreaRef.current.getTextAreaCurrent()
      const text = textAreaRef.current.getTextAreaValue()

      const cursorPosition = current.selectionStart

      let start = text.substring(0, cursorPosition)
      const end = text.substring(cursorPosition)

      start = `${start}${start ? '\n' : ''}${message} `
      textAreaRef.current.handleChangePostText(`${start}${end}`)
      current.selectionStart = start.length
      setShowPostTextHelperComponent(null)

      if (using_ai && !post.using_ai) {
        postDispatch({
          type: 'using_ai',
          data: { using_ai: true },
        })
      }
    }

    const handleClickAddTextToPost = ({ message }) => {
      const current = textAreaRef.current.getTextAreaCurrent()
      const text = textAreaRef.current.getTextAreaValue()

      const cursorPosition = current.selectionStart

      let start = text.substring(0, cursorPosition)
      const end = text.substring(cursorPosition)

      const hasSpaceBefore = start && start[start.length - 1] === ' '

      start = `${start}${start ? `${hasSpaceBefore ? '' : ' '}` : ''}${message} `
      textAreaRef.current.handleChangePostText(`${start}${end}`)
      current.selectionStart = start.length
    }

    const handleClickReplaceTextInPost = ({ message }) => {
      const { selectionStart, selectionEnd } = openAIModalOptions

      const current = textAreaRef.current.getTextAreaCurrent()
      const text = textAreaRef.current.getTextAreaValue()

      const updated_text = `${text.substring(0, selectionStart)}${message}${text.substring(selectionEnd)}`

      textAreaRef.current.handleChangePostText(updated_text)

      current.selectionStart = updated_text.length

      setShowPostTextHelperComponent(null)
    }

    const handleClickAddMentionToPost = (data) => {
      const current = textAreaRef.current.getTextAreaCurrent()
      const text = textAreaRef.current.getTextAreaValue()

      const cursorPosition = current.selectionStart

      let start = text.substring(0, cursorPosition).trim()
      if (start.length !== 0 && start[start.length - 1] === '@') {
        start = start.substring(0, start.length - 1)
      }

      if (start[start.length - 1] && start[start.length - 1] !== ' ') {
        start = `${start} `
      }

      start = `${start}@${data.name} `

      const end = text.substring(cursorPosition)

      textAreaRef.current.handleChangePostText(`${start}${end}`)
      current.selectionStart = start.length
    }

    const handleClearMentionsModalData = async ({ callbackFunction }) => {
      setMentionsModalData(null)
      await setIsMentionsModalOpen(false)
      if (callbackFunction) {
        callbackFunction()
      }
    }

    const handleUpdateMentions = (data) => {
      agencyMentionsOptions.data = [...data]
      setAgencyMentionsOptions({ ...agencyMentionsOptions })
      handleClearMentionsModalData({ callbackFunction: mentionsModalData ? mentionsModalData.callbackFunction : null })
    }

    const handleUpdateMentionsSkipped = (data) => {
      setAgencyMentionsSkipped(data)
      handleClearMentionsModalData({ callbackFunction: mentionsModalData ? mentionsModalData.callbackFunction : null })
    }

    const handleClickOpenAIModal = () => {
      openAIModalOptions.text = textAreaRef.current.getTextAreaValue()

      openAIModalOptions.isOpen = true
      setOpenAIModalOptions({ ...openAIModalOptions })

      if (!ai_assistant_prompt) {
        // eslint-disable-next-line no-use-before-define
        handleClickCloseAIPrompt()
      }
    }

    const handleClickOpenAIModalRefineText = () => {
      const textAreaValue = textAreaRef.current.getTextAreaValue()
      const { start, end } = textAreaRef.current.getSelectedTextRange() || {}

      let textAreaValueString = textAreaValue

      if (start >= 0 && end > 0) {
        textAreaValueString = textAreaValueString.substr(start, end - start).trim()

        openAIModalOptions.selectionStart = start
        openAIModalOptions.selectionEnd = end
        openAIModalOptions.rephrase = true
      }

      openAIModalOptions.text = textAreaValueString
      openAIModalOptions.isOpen = true
      openAIModalOptions.generateOnOpen = true

      setOpenAIModalOptions({ ...openAIModalOptions })
    }

    const handleClickCloseAIModal = () => {
      textAreaRef.current.setSelectedTextRangeToDefault()
      textAreaRef.current.handleShowRefineWithAI({ displayStyle: 'none' })

      openAIModalOptions.isOpen = false
      openAIModalOptions.selectionStart = null
      openAIModalOptions.selectionEnd = null
      openAIModalOptions.generateOnOpen = false
      openAIModalOptions.rephrase = false
      setOpenAIModalOptions({ ...openAIModalOptions })
    }

    const handleUpdateLinkInput = ({ value }) => {
      if (inputLinkRef && inputLinkRef.current) {
        inputLinkRef.current.value = value
      }
    }

    const handleChangeLinkUrl = ({ value }) => {
      const { link = {} } = post || {}

      link.url = value.trim()

      postDispatch({
        type: 'link',
        data: { link },
      })

      const res = value.trim().match(URL_REGEXP)

      uploadMediaComponentRef.current.setLinkUrlStates({
        ...linkUrlStates,
        ...{ isLinkUrlCorrect: !!res, isLinkUrlParsed: undefined, hasLinkUrlInformation: false },
      })
    }

    const handleChangeLinkUrlDebounce = debounce((value) => handleChangeLinkUrl({ value }), 300)

    const handleClickLinkUrlSearch = async () => {
      const { url = '' } = post.link || {}

      uploadMediaComponentRef.current.getLinkUrlInformation({ url })
    }

    const handleClickLinkUrlRemove = () => {
      handleUpdateLinkInput({ value: '' })

      postDispatch({
        type: 'link',
        data: { link: { url: '', title: '', picture_url: '', description: '' } },
      })
    }

    const debounceHandleClickLinkUrlSearch = debounce((text) => {
      const { post } = ref.current.handleReturnPost()

      let link = {}

      if (!post.link) {
        link = { shorten: { type: shortenType } }
      } else {
        link = { ...post.link }
      }

      link.url = text
      link.active = true

      handleUpdateLinkInput({ value: text })

      let postComponent = post.postComponent || POST_LINK

      uploadMediaComponentRef.current.setLinkUrlStates({
        ...linkUrlStates,
        ...{ isLinkUrlCorrect: true, isLinkUrlParsed: undefined, hasLinkUrlInformation: false },
      })

      postDispatch({
        type: 'link',
        data: { link },
      })

      if (!post.postComponent) {
        postComponent = POST_LINK

        postDispatch({ type: 'post_component', data: { postComponent, selectedProfiles } })
      }

      return uploadMediaComponentRef.current.getLinkUrlInformation({ url: text })
    }, 1000)

    const handleUpdateTextFromTextArea = debounce((text) => {
      const { link } = post

      let link_updated = {}

      if (!link && postForEdit.link) {
        link_updated = { ...postForEdit.link }
      } else {
        link_updated = link || {}
      }

      const { url = '' } = link_updated || {}

      let foundLink = ''

      const words = text
        .split('\n')
        .join(' ')
        .split(' ')
      for (let i = 0; i < words.length; i++) {
        const res = words[i].match(URL_REGEXP)
        if (res !== null) {
          foundLink = words[i]
          break
        }
      }

      if (foundLink) {
        if (!url || url !== foundLink) {
          debounceHandleClickLinkUrlSearch(foundLink)
        }
        link_updated.url = foundLink
        link_updated.active = postComponent === POST_LINK
        link_updated.present = true

        handleUpdateLinkInput({ value: foundLink })

        postDispatch({ type: 'link', data: { link: link_updated } })
      } else if (url && postComponent !== POST_LINK) {
        link_updated.active = false
        link_updated.present = false

        postDispatch({ type: 'link', data: { link: link_updated } })
      }

      postDispatch({ type: 'text', data: { text } })
    }, 300)

    const handleUpdateTextFromAIModal = ({ text: message }) => {
      if (openAIModalOptions.rephrase) {
        handleClickReplaceTextInPost({ message })
      } else {
        handleClickAddTextToPostNewLine({ message })
      }

      if (!post.using_ai) {
        postDispatch({
          type: 'using_ai',
          data: { using_ai: true },
        })
      }

      handleClickCloseAIModal()
    }

    const onChangeUpdateAllProfilesCustomizations = ({
      post: { customizations },
      isDefault = false,
      syncData = false,
    }) => {
      postDispatch({
        type: 'customizations',
        data: { customizations, selectedProfiles: syncData ? selectedProfiles : null, isDefault },
      })
    }

    const onChangeUpdateSpecificProfileCustomizations = ({ customizations, network, profileId }) => {
      postDispatch({ type: 'profile_customizations', network, profileId, data: { customizations } })
    }

    const handleOpenMentionComponent = () => {
      if (showPostTextHelperComponent !== POST_AT) {
        handleClickSelectPostTextHelperComponent(POST_AT)
      }
      const hasBeenOpenedOnce = localStorage.getItem(LOCAL_STORAGE_SHOW_MENTION_SELECTOR)
      if (!hasBeenOpenedOnce && agencyMentionsOptions.data && agencyMentionsOptions.data.length === 0) {
        setIsMentionsModalOpen(true)
        localStorage.setItem(LOCAL_STORAGE_SHOW_MENTION_SELECTOR, 'true')
      }
    }

    const handleSwitchUtmTracking = () => {
      const { link = {} } = post

      link.utm_tracking = !post.link.utm_tracking
      if (!link.tracking || !link.tracking.params) {
        if (!link.tracking) {
          link.tracking = {}
        }

        link.tracking.params = [...JSON.parse(JSON.stringify(DEFAULT_UTM_PARAMS))]

        link.tracking.params.forEach((item) => {
          if (typeof item.type === 'string' && agencyTrackingTypes.length !== 0) {
            item.type = agencyTrackingTypes.find(({ value }) => value === item.type)
          }
        })
      }

      postDispatch({ type: 'link', data: { link } })
    }

    const handleSwitchLinkShorten = () => {
      let { link } = post

      if (!link) {
        link = {
          active: postComponent === POST_LINK,
        }
      }

      if (!link.shorten) {
        link.shorten = {}
      }

      if (shortenType === 'DEFAULT') {
        link.shorten = { type: 'NONE' }
      } else {
        link.shorten = { type: 'DEFAULT' }
      }

      postDispatch({ type: 'link', data: { link } })
    }

    const handleChangeParameters = ({ key, value, index }) => {
      link.tracking.params[index][key] = value

      postDispatch({ type: 'link', data: { link } })
    }

    const handleClickRemoveParam = ({ index }) => {
      link.tracking.params.splice(index, 1)

      postDispatch({ type: 'link', data: { link } })
    }

    const handleClickAddParam = () => {
      const foundType = agencyTrackingTypes.find(({ value }) => value === CUSTOM_TYPE)
      if (foundType) {
        link.tracking.params.push({ name: '', type: foundType, value: '' })

        postDispatch({ type: 'link', data: { link } })
      }
    }

    const handleSwitchVideoProcessing = () => {
      const { customizations = {} } = post

      if (!post.customizations) {
        post.customizations = {}
      }
      customizations.video_processing = !video_processing

      postDispatch({ type: 'customizations', data: { customizations } })
    }

    const handleSwitchImageProcessing = () => {
      const { customizations = {} } = post

      if (!post.customizations) {
        post.customizations = {}
      }
      customizations.image_processing = !image_processing

      postDispatch({ type: 'customizations', data: { customizations } })
    }

    const handleClickCloseAIPrompt = async () => {
      intro.ai_assistant_prompt = true
      setIntro({ ...intro })
      await handleChangeUserIntroFeature({ step_helper: 'ai_assistant_prompt' })
    }

    const handleClickSetCustomizationsToDefault = () => {
      const { customizations = {} } = post
      selectedProfiles.forEach((profile) => {
        const { code, id } = profile

        const { [code]: networkCustomizations } = customizations || {}
        let { [id]: profileCustomizations } = networkCustomizations || {}

        if (!profileCustomizations) {
          profileCustomizations = {}
        }

        const keys = ['message', 'postComponent', 'postImages', 'postVideos', 'postDocuments', 'postMixedMedias']

        keys.forEach((key) => {
          delete profileCustomizations[key]
        })
      })

      postDispatch({ type: 'customizations', data: { customizations, selectedProfiles } })

      setForceCloseNetworkPreviewCaption(new Date().getTime())
    }

    const handleClickCopyText = async ({ text }) => {
      await navigator.clipboard.writeText(text)

      Alert.success(`Text has been copied.`, { timeout: 5000 })
    }

    const handleSubmitDirection = async ({ direction }) => {
      window.dispatchEvent(
        new CustomEvent('handleUpdateUserDirection', {
          detail: { direction },
        })
      )

      try {
        setIsDirectionChanging(true)

        const response = await request({
          method: 'PATCH',
          path: `me/preferences`,
          body: { direction },
        })

        const { error } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          if (!user.settings) {
            user.settings = {}
          }

          user.settings.direction = direction

          setUser({ ...user })
        }
      } catch (error) {
        errorHelper({ error, componentName: PostCreationComponent.displayName, functionName: 'handleSubmitDirection' })
      } finally {
        setIsDirectionChanging(false)
      }
    }

    const { SYMBOLS_ALLOWED_LENGTH_ERROR, DUPLICATE_HASHTAGS_WARNING } = useMemo(() => {
      let SYMBOLS_ALLOWED_LENGTH_ERROR = false
      NETWORK_LIMITS.forEach((network_limit) => {
        const { code, postTextMaxLimit, postTextMaxLimitPaid = 0 } = network_limit

        const foundSelectedProfiles =
          code === TWITTER
            ? selectedProfiles.filter((value) => value.code === code)
            : selectedProfiles.find((value) => value.code === code)

        network_limit.display = (code === TWITTER
        ? foundSelectedProfiles.length
        : foundSelectedProfiles)
          ? 'flex'
          : 'none'

        if (network_limit.display !== 'none') {
          let updatedPostText = postText || ''

          network_limit.symbolsAllowedLength = postTextMaxLimit - updatedPostText.length

          if (code === TWITTER) {
            if (post.link && post.link.url && post.link.active) {
              if (!updatedPostText.includes(post.link.url)) {
                const hasSpaceAtTheEnd = updatedPostText.charAt(updatedPostText.length - 1) === ' '
                updatedPostText = `${updatedPostText}${hasSpaceAtTheEnd ? '' : ' '}${post.link.url}`
              }
            }

            network_limit.twitterProfilesHaveFreePlan = false
            network_limit.twitterProfilesHavePaidPlan = false

            for (let i = 0; i < foundSelectedProfiles.length; i++) {
              const profileType = getProfileFlavor({ profile: foundSelectedProfiles[i] })

              if (profileType === PROFILE_TYPE_TWITTER_FREE) {
                network_limit.twitterProfilesHaveFreePlan = true
              } else {
                network_limit.twitterProfilesHavePaidPlan = true
              }

              if (network_limit.twitterProfilesHaveFreePlan && network_limit.twitterProfilesHavePaidPlan) {
                break
              }
            }

            const updatedPostTextLength = twitter.getTweetLength(updatedPostText)

            network_limit.symbolsAllowedLength = network_limit.twitterProfilesHaveFreePlan
              ? postTextMaxLimit - updatedPostTextLength
              : 0
            network_limit.symbolsPaidAllowedLength = network_limit.twitterProfilesHavePaidPlan
              ? postTextMaxLimitPaid - updatedPostTextLength
              : 0
          } else if (code === PINTEREST) {
            network_limit.symbolsAllowedLength =
              postTextMaxLimit -
              updatedPostText
                .split('\n')
                .map((line) => {
                  return line
                    .split(' ')
                    .filter((word) => !word.match(URL_REGEXP))
                    .join(' ')
                })
                .join('\n').length
          } else if (code === INSTAGRAM) {
            network_limit.symbolsAllowedLength =
              postTextMaxLimit - instagramTextSymbolCounter({ message: updatedPostText })
          } else if (code === BLUESKY) {
            const { length: bsk_length, warning: bsk_warn } = blueskyGetPostLength({
              text: updatedPostText,
              post,
              user,
              profile: foundSelectedProfiles && foundSelectedProfiles.length > 0 ? foundSelectedProfiles[0] : undefined,
            })

            network_limit.symbolsAllowedLength = postTextMaxLimit - bsk_length
          }

          if (postTextMaxLimit && network_limit.symbolsAllowedLength < 0) {
            SYMBOLS_ALLOWED_LENGTH_ERROR = true
          }
        }
      })

      let DUPLICATE_HASHTAGS_WARNING = ''

      const hashtags = twitter.extractHashtags(postText)

      if (hashtags.length !== 0) {
        const uniqueElements = new Set()

        const duplicates = []

        hashtags.forEach((item) => {
          if (uniqueElements.has(item)) {
            duplicates.push(item)
          } else {
            uniqueElements.add(item)
          }
        })

        if (duplicates.length !== 0) {
          DUPLICATE_HASHTAGS_WARNING = `Duplicate hashtags: ${duplicates.join(', ')}`
        }
      }

      return { SYMBOLS_ALLOWED_LENGTH_ERROR, DUPLICATE_HASHTAGS_WARNING }
    }, [postText, link, selectedProfiles])

    const { CUSTOMIZATIONS_MESSAGE_WARNING } = useMemo(() => {
      let CUSTOMIZATIONS_MESSAGE_WARNING = ''

      if (post.status !== PUBLISHED && post.status !== FAILED && post.customizations) {
        const networksWithCustomizedCaptionOrMedia = new Set()

        const captionCustomized = { once: false, per_profile: false }
        const mediasCustomized = { once: false, per_profile: false }

        for (const networkCustomizationKey in post.customizations) {
          if (
            typeof post.customizations[networkCustomizationKey] === 'object' &&
            post.customizations[networkCustomizationKey] !== null &&
            !Array.isArray(post.customizations[networkCustomizationKey])
          ) {
            for (const profileCustomizationKey in post.customizations[networkCustomizationKey]) {
              if (
                post.customizations[networkCustomizationKey].hasOwnProperty(profileCustomizationKey) &&
                post.customizations[networkCustomizationKey][profileCustomizationKey]
              ) {
                captionCustomized.per_profile = false
                mediasCustomized.per_profile = false

                if (post.customizations[networkCustomizationKey][profileCustomizationKey].message) {
                  captionCustomized.once = true
                  captionCustomized.per_profile = true
                }

                if (
                  typeof post.customizations[networkCustomizationKey][profileCustomizationKey].sync_media_keys !==
                    'undefined' &&
                  (post.customizations[networkCustomizationKey][profileCustomizationKey].sync_media_keys !==
                    post.sync_media_keys ||
                    (!post.sync_media_keys &&
                      post.customizations[networkCustomizationKey][profileCustomizationKey].sync_media_keys))
                ) {
                  mediasCustomized.once = true
                  mediasCustomized.per_profile = true
                }

                if (captionCustomized.per_profile || mediasCustomized.per_profile) {
                  const foundSelectedProfile = selectedProfiles.find(({ id }) => `${id}` === profileCustomizationKey)

                  if (foundSelectedProfile) {
                    networksWithCustomizedCaptionOrMedia.add(foundSelectedProfile.display)
                  }
                }
              }
            }
          }
        }

        if (networksWithCustomizedCaptionOrMedia.size > 0) {
          let networkString = ''

          const networksWithCustomizedCaptionOrMediaTemp = [...networksWithCustomizedCaptionOrMedia]

          networksWithCustomizedCaptionOrMediaTemp.forEach((item, index) => {
            if (index === 0) {
              networkString = item
            } else {
              networkString += `${index === networksWithCustomizedCaptionOrMedia.size - 1 ? ' and ' : ', '}${item}`
            }
          })

          CUSTOMIZATIONS_MESSAGE_WARNING = `${
            networksWithCustomizedCaptionOrMedia.size === 1
              ? `${captionCustomized.once ? 'Caption' : ''}${
                  captionCustomized.once && mediasCustomized.once ? ' and ' : ''
                }${mediasCustomized.once ? 'Media' : ''}`
              : `${captionCustomized.once ? 'Captions' : ''}${
                  captionCustomized.once && mediasCustomized.once ? ' and ' : ''
                }${mediasCustomized.once ? 'Media' : ''}`
          } for ${networkString} ${networksWithCustomizedCaptionOrMedia.size === 1 ? 'was' : 'were'} changed and need${
            networksWithCustomizedCaptionOrMedia.size === 1 ? 's' : ''
          } to be managed on the right.${' '}👉`
        }
      }

      return { CUSTOMIZATIONS_MESSAGE_WARNING }
    }, [post, selectedProfiles])

    return (
      <Flex m="0 auto" width="100%" height="100%">
        <PostWrapper width="45%" height="100%" flexDirection="column">
          <Scrollbars
            universal
            autoHide
            renderTrackHorizontal={(props) => (
              <div {...props} className="track-horizontal" style={{ display: 'none' }} />
            )}
            renderThumbHorizontal={(props) => (
              <div {...props} className="thumb-horizontal" style={{ display: 'none' }} />
            )}
          >
            {/* Not sure if this is used */}
            {error && (
              <MessageWrapper mx="m" alignItems="center" px="m" py="s" mt="m">
                <Text
                  fontWeight="bold"
                  color="error"
                  dangerouslySetInnerHTML={{
                    __html: error,
                  }}
                />
              </MessageWrapper>
            )}

            <Flex alignItems="center" width="100%" mt="m" px="m" pt="xs" justifyContent="space-between">
              <Text fontSize="m">{post && post.id ? 'Edit post for all profiles' : 'Your post'}</Text>
            </Flex>

            {post && post.id && (
              <Text fontSize="xs" px="m" color="secondaryText">
                To edit individual post use customization area 👉
              </Text>
            )}

            <Flex px="m">
              {CUSTOMIZATIONS_MESSAGE_WARNING && (
                <Flex flexDirection="column" width="100%">
                  <MessageWrapper px="m" py="s" mt="s" color="#DC8449" flexDirection="column">
                    <Text color="#DC8449">{CUSTOMIZATIONS_MESSAGE_WARNING}</Text>
                    <Text color="#DC8449">
                      <TextWrapper
                        as="span"
                        color="primary"
                        fontSize="xs"
                        onClick={() => {
                          confirm({
                            fn: () => () => {
                              handleClickSetCustomizationsToDefault()
                            },
                            message: `You are about to reset all customized captions and media to the default values. <a style="color: ${COLOR_CONSTANTS.COBALT}; text-decoration: none;" href="https://support.vistasocial.com/hc/en-us/articles/19757042609691" target="_blank">Learn more</a>.`,
                            action: `Yes, reset`,
                            cancel: 'No, cancel',
                          })
                        }}
                      >
                        Reset all customizations
                      </TextWrapper>
                    </Text>
                  </MessageWrapper>
                </Flex>
              )}
            </Flex>
            <StyledTextAreaWrapper mx="m" mt="m" hasError={SYMBOLS_ALLOWED_LENGTH_ERROR}>
              <TextAreaComponent
                textValue={postText}
                handleUpdateTextFromTextArea={handleUpdateTextFromTextArea}
                handleOpenMentionComponent={handleOpenMentionComponent}
                handleClickOpenAIModal={handleClickOpenAIModal}
                ref={textAreaRef}
              />
              <Flex alignItems="center" pt="s" flexWrap="wrap">
                <Box position="relative">
                  <IconWrapper
                    onClick={() => {
                      handleClickSelectPostTextHelperComponent(POST_EMOJI)
                    }}
                    isActive={showPostTextHelperComponent === POST_EMOJI}
                  >
                    <Icon.Smile width="20px" height="20px" />
                  </IconWrapper>
                  {showPostTextHelperComponent === POST_EMOJI && (
                    <PostTextHelperComponentWrapper left="-1px" ref={emojiRef}>
                      <Picker onEmojiSelect={handleClickSelectEmoji} previewPosition="none" theme="light" />
                    </PostTextHelperComponentWrapper>
                  )}
                </Box>
                <Box position="relative">
                  <IconWrapper
                    onClick={() => {
                      handleClickSelectPostTextHelperComponent(POST_HASHTAG)
                    }}
                    isActive={showPostTextHelperComponent === POST_HASHTAG}
                  >
                    <Icon.HashTag width="20px" height="20px" />
                  </IconWrapper>
                  {showPostTextHelperComponent === POST_HASHTAG && (
                    <PostTextHelperComponentWrapper left="-37px" minWidth="380px" ref={hashtagRef}>
                      <StyledBox pl="m">
                        <HashTagSelector
                          user={user}
                          ref={hashTagSelectorRef}
                          handleClickAddHashTagToPost={handleClickAddTextToPost}
                          selectedEntities={selectedEntities}
                          selectedProfiles={selectedProfiles}
                        />
                      </StyledBox>
                    </PostTextHelperComponentWrapper>
                  )}
                </Box>
                <Box position="relative">
                  <IconWrapper
                    onClick={() => {
                      handleClickSelectPostTextHelperComponent(POST_SNIPPET)
                    }}
                    isActive={showPostTextHelperComponent === POST_SNIPPET}
                  >
                    <Icon.Snippet width="20px" height="20px" />
                  </IconWrapper>
                  {showPostTextHelperComponent === POST_SNIPPET && (
                    <PostTextHelperComponentWrapper left="-37px" minWidth="380px" ref={snippetRef}>
                      <StyledBox pl="m">
                        <SnippetSelector
                          user={user}
                          ref={snippetSelectorRef}
                          handleClickAddText={handleClickAddTextToPostNewLine}
                          type={SNIPPET_TYPE_CAPTION}
                          selectedEntities={selectedEntities}
                          selectedProfiles={selectedProfiles}
                        />
                      </StyledBox>
                    </PostTextHelperComponentWrapper>
                  )}
                </Box>
                <Box position="relative">
                  <IconWrapper
                    onClick={() => {
                      handleClickSelectPostTextHelperComponent(POST_VARIABLE)
                    }}
                    isActive={showPostTextHelperComponent === POST_VARIABLE}
                  >
                    <Icon.Brackets width="20px" height="20px" />
                  </IconWrapper>
                  {showPostTextHelperComponent === POST_VARIABLE && (
                    <PostTextHelperComponentWrapper left="-37px" minWidth="300px" maxWidth="300px" ref={variableRef}>
                      <StyledBox pl="m">
                        <VariableSelector
                          user={user}
                          savedVariables={savedVariables}
                          handleClickAddVariableToPost={handleClickAddTextToPost}
                          handleClickOpenVariablesModal={handleClickOpenVariablesModal}
                        />
                      </StyledBox>
                    </PostTextHelperComponentWrapper>
                  )}
                </Box>
                <Box position="relative">
                  <IconWrapper
                    id="publish-modal-ai-assistant"
                    onClick={() => {
                      handleClickSelectPostTextHelperComponent(POST_AT)
                    }}
                    isActive={showPostTextHelperComponent === POST_AT}
                  >
                    <Icon.At width="18px" height="18px" />
                  </IconWrapper>
                  {showPostTextHelperComponent === POST_AT && (
                    <PostTextHelperComponentWrapper left="-73px" minWidth="425px" ref={mentionsRef}>
                      <StyledBox pl="m">
                        <MentionSelector
                          ref={mentionSelectorRef}
                          user={user}
                          setUser={setUser}
                          handleClickAddMentionToPost={handleClickAddMentionToPost}
                          isOpen={false}
                          agencyMentions={agencyMentionsOptions.data}
                          agencyMentionsLoading={agencyMentionsOptions.isLoading}
                          agencyMentionsSkipped={agencyMentionsSkipped}
                          handleUpdateMentions={handleUpdateMentions}
                          isMentionsModalOpen={isMentionsModalOpen}
                          mentionsModalData={mentionsModalData}
                          handleClearMentionsModalData={handleClearMentionsModalData}
                          handleUpdateMentionsSkipped={handleUpdateMentionsSkipped}
                          selectedProfiles={selectedProfiles}
                        />
                      </StyledBox>
                    </PostTextHelperComponentWrapper>
                  )}
                </Box>
                <Box position="relative">
                  <IconWrapper
                    onClick={() => {
                      handleClickSelectPostTextHelperComponent(POST_TEXT_EDITOR)
                    }}
                    isActive={showPostTextHelperComponent === POST_TEXT_EDITOR}
                  >
                    <Icon.Text width="16px" height="18px" />
                  </IconWrapper>
                  {showPostTextHelperComponent === POST_TEXT_EDITOR && (
                    <PostTextHelperComponentWrapper left="-109px" ref={textVariantRef}>
                      <StyledBox>
                        <Grid gridTemplateColumns="repeat(2, 1fr)">
                          {textVariantOffsets.map(({ value, label }) => (
                            <StyledVariantOffsetWrapper
                              key={value}
                              py="xxs"
                              px="s"
                              alignItems="center"
                              onClick={() => {
                                const textAreaValue = textAreaRef.current.getTextAreaValue()
                                const { start, end } = textAreaRef.current.getSelectedTextRange() || {}

                                let textAreaValueString = textAreaValue

                                if (start >= 0 && end > 0) {
                                  textAreaValueString = textAreaValueString.substr(start, end - start)
                                } else {
                                  textAreaValueString = ''
                                }

                                const { text, message, type } = transformTextToUnicode({
                                  text: textAreaValueString,
                                  value,
                                })

                                if (type) {
                                  Alert[type](message, { timeout: 5000 })
                                } else {
                                  const firstPart = textAreaValue.substring(0, start)
                                  const lastPart = textAreaValue.substring(end)

                                  const updatedText = `${firstPart}${text}${lastPart}`

                                  textAreaRef.current.handleChangeHiddenBoxText(updatedText)
                                  textAreaRef.current.handleChangePostText(updatedText)
                                }
                              }}
                            >
                              <Text fontSize="xs">{label}</Text>
                            </StyledVariantOffsetWrapper>
                          ))}
                        </Grid>
                      </StyledBox>
                    </PostTextHelperComponentWrapper>
                  )}
                </Box>
                <Box position="relative" id="ai-body-icon">
                  <IconWrapper onClick={handleClickOpenAIModal}>
                    <Image source="/assets/magic_wand.svg" width="22px" height="22px" />
                  </IconWrapper>
                </Box>

                {!isAdvocate && (
                  <Box position="relative">
                    <Box position="relative">
                      <Box
                        position="relative"
                        onMouseEnter={(e) => {
                          if (tooltipRef) {
                            tooltipRef.current.handleShowTooltip({
                              contentComp: (
                                <Box p="s" maxWidth="350px">
                                  <Text textAlign="center" fontSize="s">
                                    Brand Safety & Compliance Policy
                                  </Text>
                                </Box>
                              ),
                              wrapperComp: e.currentTarget,
                              defaultYPosition: 'top',
                            })
                          }
                        }}
                        onMouseLeave={() => {
                          if (tooltipRef) {
                            tooltipRef.current.handleHideTooltip()
                          }
                        }}
                      >
                        <IconWrapper onClick={handleClickOpenCompliancePreviewModal}>
                          <Icon.Shield width="18px" height="18px" />
                        </IconWrapper>
                      </Box>
                    </Box>
                  </Box>
                )}

                <Box>
                  {isDirectionChanging ? (
                    <IconWrapper>
                      <Flex justifyContent="center" className="cliploader-wrapper" pt="6px">
                        <ClipLoader size="18" />
                      </Flex>
                    </IconWrapper>
                  ) : (
                    <Flex>
                      <Flex>
                        <IconWrapper
                          onClick={() => {
                            if (tooltipRef) {
                              tooltipRef.current.handleHideTooltip()
                            }

                            if (direction === DIRECTION_LEFT_TO_RIGHT) {
                              handleSubmitDirection({ direction: DIRECTION_RIGHT_TO_LEFT })
                            } else if (direction === DIRECTION_RIGHT_TO_LEFT) {
                              handleSubmitDirection({ direction: DIRECTION_LEFT_TO_RIGHT })
                            }
                          }}
                          pt="xs"
                          onMouseEnter={(e) => {
                            if (tooltipRef) {
                              tooltipRef.current.handleShowTooltip({
                                contentComp: (
                                  <Box p="s">
                                    <Text textAlign="center" fontSize="s">
                                      Change content direction to{' '}
                                      {direction === DIRECTION_LEFT_TO_RIGHT ? 'right-to-left' : 'left-to-right'}
                                    </Text>
                                  </Box>
                                ),
                                wrapperComp: e.currentTarget,
                                defaultYPosition: 'top',
                              })
                            }
                          }}
                          onMouseLeave={() => {
                            if (tooltipRef) {
                              tooltipRef.current.handleHideTooltip()
                            }
                          }}
                        >
                          {direction === DIRECTION_LEFT_TO_RIGHT ? (
                            <Icon.RTL width="20px" height="20px" fill="icon_color_gray" />
                          ) : (
                            <Icon.LTR width="20px" height="20px" fill="icon_color_gray" />
                          )}
                        </IconWrapper>
                      </Flex>
                    </Flex>
                  )}
                </Box>
                <Flex flex="1 1 auto" />

                <UploadMediaComponent
                  ref={uploadMediaComponentRef}
                  user={user}
                  selectedProfiles={selectedProfiles}
                  selectedEntities={selectedEntities}
                  post={{
                    postComponent,
                    postText,
                    link,
                    postImages,
                    postVideos,
                    postDocuments,
                    postMixedMedias,
                    post_id: post.post_id,
                    profile_id: post.profile?.profileId,
                    customizations_post_type: post.customizations_post_type,
                  }}
                  uploadingMediasAmount={uploadingMediasAmount}
                  setUploadingMediasAmount={setUploadingMediasAmount}
                  handleSpecialRerender={() => {
                    setSpecialRerenderKey(new Date().getTime())
                  }}
                  handleUpdateLinkInput={handleUpdateLinkInput}
                />
              </Flex>
            </StyledTextAreaWrapper>

            {DUPLICATE_HASHTAGS_WARNING && (
              <Text mt="xs" px="m" color="warning" fontSize="xs">
                {DUPLICATE_HASHTAGS_WARNING}
              </Text>
            )}

            <StyledNetworkLimitsWrapper mt="xs" px="m" flexWrap="wrap">
              {NETWORK_LIMITS.map(
                ({
                  code,
                  symbolsAllowedLength = 0,
                  symbolsPaidAllowedLength = 0,
                  display,
                  disabled,
                  twitterProfilesHaveFreePlan,
                  twitterProfilesHavePaidPlan,
                }) => {
                  if (disabled) {
                    return null
                  }

                  const hasError = symbolsAllowedLength < 0
                  const hasErrorPaid = symbolsPaidAllowedLength < 0
                  return (
                    <Flex
                      key={code}
                      display={display}
                      alignItems="center"
                      px="xs"
                      py="xxs"
                      mt="xs"
                      bg={
                        hasError
                          ? transparentize(0.95, COLOR_CONSTANTS.FLUORESCENT_RED)
                          : transparentize(0.5, COLOR_CONSTANTS.WHITE)
                      }
                      borderRadius={radius.l}
                    >
                      <ImageComponent src={getSocialNetworkIconByName(code).enabled} width="16px" height="16px" />
                      <Flex ml="xs" mt="1px">
                        {code === TWITTER ? (
                          <Fragment>
                            {twitterProfilesHaveFreePlan && (
                              <Text fontSize="xxs" color={hasError ? 'error' : 'primaryText'}>
                                {symbolsAllowedLength}
                              </Text>
                            )}

                            {twitterProfilesHaveFreePlan && twitterProfilesHavePaidPlan && (
                              <Text fontSize="xxs" mx="xs" color="primaryText">
                                /
                              </Text>
                            )}

                            {twitterProfilesHavePaidPlan && (
                              <Text fontSize="xxs" color={hasErrorPaid ? 'error' : 'primaryText'}>
                                {symbolsPaidAllowedLength}
                              </Text>
                            )}
                          </Fragment>
                        ) : (
                          <Text fontSize="xxs" color={hasError ? 'error' : 'primaryText'}>
                            {symbolsAllowedLength}
                          </Text>
                        )}
                      </Flex>
                    </Flex>
                  )
                }
              )}
            </StyledNetworkLimitsWrapper>

            <Flex flexDirection="column" position="relative">
              <StyledBox
                mx="m"
                mt="m"
                display={
                  (postComponent === POST_IMAGE && postImages && postImages.length !== 0) ||
                  (postComponent === POST_VIDEO && postVideos && postVideos.length !== 0) ||
                  (postComponent === POST_DOCUMENT && postDocuments && postDocuments.length !== 0) ||
                  (postComponent === POST_MIXED_MEDIA && postMixedMedias && postMixedMedias.length !== 0)
                    ? 'block'
                    : 'none'
                }
              >
                <Flex
                  justifyContent="space-between"
                  pt="m"
                  mx="m"
                  display={
                    postComponent === POST_VIDEO ||
                    (postComponent === POST_MIXED_MEDIA &&
                      postMixedMedias &&
                      postMixedMedias.find(({ type }) => type && type.includes(VIDEO)))
                      ? 'flex'
                      : 'none'
                  }
                >
                  <Flex alignItems="center">
                    <AddMediaWrapper onClick={handleSwitchVideoProcessing} alignItems="center">
                      <Switch isOn={video_processing} onClick={() => {}} text="" />
                      <Text ml="s" fontWeight="light" fontSize="xs" as="span">
                        Video processing.
                      </Text>
                    </AddMediaWrapper>
                    &nbsp;
                    <StyledLearnMoreText
                      as="a"
                      href="https://support.vistasocial.com/hc/en-us/articles/10208587806363-Video-processing"
                      target="_blank"
                      color="primary"
                      fontWeight="light"
                      fontSize="xs"
                    >
                      Learn more
                    </StyledLearnMoreText>
                  </Flex>
                </Flex>

                <Flex
                  justifyContent="space-between"
                  pt="m"
                  mx="m"
                  display={
                    postComponent === POST_IMAGE ||
                    (postComponent === POST_MIXED_MEDIA &&
                      postMixedMedias &&
                      postMixedMedias.find(({ type }) => type && type.includes(IMAGE)))
                      ? 'flex'
                      : 'none'
                  }
                >
                  <Flex alignItems="center">
                    <AddMediaWrapper onClick={handleSwitchImageProcessing} alignItems="center">
                      <Switch isOn={image_processing} onClick={() => {}} text="" />
                      <Text ml="s" fontWeight="light" fontSize="xs" as="span">
                        Image processing.
                      </Text>
                    </AddMediaWrapper>
                    &nbsp;
                    <StyledLearnMoreText
                      as="a"
                      href="https://support.vistasocial.com/hc/en-us/articles/32582651918875-Image-processing"
                      target="_blank"
                      color="primary"
                      fontWeight="light"
                      fontSize="xs"
                    >
                      Learn more
                    </StyledLearnMoreText>
                  </Flex>
                </Flex>

                <UploadedMediasComponent
                  user={user}
                  post={{ postComponent, postImages, postVideos, postDocuments, postMixedMedias }}
                  selectedProfiles={selectedProfiles}
                  selectedEntities={selectedEntities}
                  uploadMediaComponentRef={uploadMediaComponentRef}
                />
              </StyledBox>
            </Flex>
            <StyledBox
              mx="m"
              mt="m"
              p="m"
              position="relative"
              display={postComponent === POST_LINK || linkPresent ? 'block' : 'none'}
            >
              <StyledProgressWrapper
                display={linkUrlStates.isGettingSiteInformation ? 'flex' : 'none'}
                alignItems="center"
                justifyContent="center"
                className="cliploader-wrapper"
              >
                <ClipLoader size="50" />
              </StyledProgressWrapper>
              {postComponent === POST_LINK && (
                <Flex flexDirection="column" mb="m">
                  <Text mb="xs">Link URL</Text>
                  <Flex flexDirection="column">
                    <Flex alignItems="center" justifyContent="space-between">
                      <Flex
                        width="100%"
                        flexDirection="column"
                        position="relative"
                        onMouseLeave={() => {
                          if (
                            !linkUrlStates.isGettingSiteInformation &&
                            !linkUrlStates.isLinkUrlParsed &&
                            linkUrlStates.isLinkUrlCorrect
                          ) {
                            handleClickLinkUrlSearch()
                          }
                        }}
                      >
                        <Input
                          innerRef={inputLinkRef}
                          placeholder="Insert the link url"
                          label=""
                          defaultValue={url || ''}
                          onChange={(e) => handleChangeLinkUrlDebounce(e.target.value)}
                          width="100%"
                        />
                      </Flex>
                      <Flex>
                        <Tooltip
                          wrapperComp={
                            <LinkControlsWrapper
                              onClick={() => {
                                if (!linkUrlStates.isGettingSiteInformation) {
                                  handleClickLinkUrlSearch()
                                }
                              }}
                              mx="s"
                            >
                              <Icon.Search height="16px" />
                            </LinkControlsWrapper>
                          }
                          message="Generate link preview"
                          width="150px"
                          left="-70px"
                          isTriangleVisible={false}
                          defaultTopOffset={40}
                        />
                        <Tooltip
                          wrapperComp={
                            <LinkControlsWrapper
                              onClick={() => {
                                if (!linkUrlStates.isGettingSiteInformation) {
                                  handleClickLinkUrlRemove()
                                }
                              }}
                            >
                              <ImageComponent width="12px" height="14px" src="/assets/clear.svg" />
                            </LinkControlsWrapper>
                          }
                          message="Remove link"
                          width="100px"
                          left="-70px"
                          isTriangleVisible={false}
                          defaultTopOffset={40}
                        />
                      </Flex>
                    </Flex>
                    {url.length !== 0 &&
                      !linkUrlStates.isGettingSiteInformation &&
                      ((!linkUrlStates.isLinkUrlCorrect && typeof linkUrlStates.isLinkUrlCorrect !== 'undefined') ||
                        (!linkUrlStates.isLinkUrlParsed && typeof linkUrlStates.isLinkUrlParsed !== 'undefined')) && (
                        <ErrorLabel
                          mt="xxs"
                          textAlign="left"
                          error={
                            !linkUrlStates.isLinkUrlCorrect
                              ? 'Please enter a valid link.'
                              : 'Unable to generate link preview but you can still schedule this post.'
                          }
                        />
                      )}
                  </Flex>
                  {url.length !== 0 && linkUrlStates.isLinkUrlCorrect && linkUrlStates.isLinkUrlParsed && (
                    <Flex mt="m" flexDirection="column">
                      <Flex>
                        {/* <StyledLabel htmlFor="linkUrlPicture"> */}
                        {linkUrlStates.hasLinkUrlInformation ? (
                          <Flex>
                            {isUploadingLinkImage ? (
                              <StyledLabel>
                                <Flex
                                  alignItems="center"
                                  justifyContent="center"
                                  width="80px"
                                  height="80px"
                                  className="cliploader-wrapper"
                                >
                                  <ClipLoader size="50" />
                                </Flex>
                              </StyledLabel>
                            ) : (
                              <Fragment>
                                {picture_url && (
                                  <StyledLabel>
                                    <PostMediaWrapper>
                                      <StyledImage
                                        width="80px"
                                        height="80px"
                                        source={picture_url}
                                        fallbackSource={picture_url || '/assets/link.svg'}
                                      />
                                      {/* <LinkIconWrapper position="absolute"> */}
                                      {/*  <Icon.Camera /> */}
                                      {/* </LinkIconWrapper> */}
                                    </PostMediaWrapper>
                                  </StyledLabel>
                                )}
                              </Fragment>
                            )}
                            <Flex flexDirection="column" width="100%">
                              <Flex width="100%" alignItems="center" height="34px">
                                <Flex flexDirection="column" width="100%" position="relative">
                                  <Text as="span" fontWeight="medium">
                                    {truncate(title, 45)}
                                  </Text>
                                  {title && (
                                    <StyledCopyIconWrapper
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        handleClickCopyText({ text: title })
                                      }}
                                    >
                                      <Image src="/assets/copy.svg" width="14px" height="14px" />
                                    </StyledCopyIconWrapper>
                                  )}
                                </Flex>
                              </Flex>
                              <Flex width="100%" alignItems="center">
                                <Flex flexDirection="column" width="100%" position="relative">
                                  <Text as="span" fontSize="xs">
                                    {truncate(description, 150)}
                                  </Text>
                                  {description && (
                                    <StyledCopyIconWrapper
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        handleClickCopyText({ text: description })
                                      }}
                                    >
                                      <Image src="/assets/copy.svg" width="14px" height="14px" />
                                    </StyledCopyIconWrapper>
                                  )}
                                </Flex>
                              </Flex>
                            </Flex>
                          </Flex>
                        ) : (
                          <Flex>
                            <Text color="error">
                              We are unable to download link preview but you can still publish this post.{' '}
                              <Text
                                color="error"
                                as="a"
                                target="_blank"
                                href="https://support.vistasocial.com/hc/en-us/articles/4489742499483"
                              >
                                Learn more
                              </Text>
                              .
                            </Text>
                          </Flex>
                        )}
                      </Flex>
                    </Flex>
                  )}
                </Flex>
              )}

              {url &&
                linkUrlStates.isLinkUrlCorrect &&
                !linkUrlStates.isGettingSiteInformation &&
                (linkPresent || postComponent === POST_LINK) && (
                  <Flex flexDirection="column">
                    <Flex>
                      <Switch
                        isOn={shortenType === 'DEFAULT'}
                        onClick={() => {
                          if (featuresEnabled[FEATURE_LINK_SHORTENER].enabled) {
                            handleSwitchLinkShorten()
                          } else {
                            Alert.error(featuresEnabled[FEATURE_LINK_SHORTENER].description, { timeout: 10000 })
                          }
                        }}
                        text={shortenType === 'DEFAULT' ? 'Shortening defaults' : 'No shortening'}
                      />
                    </Flex>
                    <Flex mt="m" flexDirection="column">
                      <Switch
                        isOn={utm_tracking}
                        onClick={() => {
                          if (featuresEnabled[FEATURE_UTM_TRACKING].enabled) {
                            handleSwitchUtmTracking()
                          } else {
                            Alert.error(featuresEnabled[FEATURE_UTM_TRACKING].description, { timeout: 10000 })
                          }
                        }}
                        text={utm_tracking ? 'UTM defaults' : 'No UTM tracking'}
                      />
                      {utm_tracking && (
                        <Flex flexDirection="column">
                          <UrlTrackingParameters
                            trackingTypes={agencyTrackingTypes}
                            handleChangeParameters={handleChangeParameters}
                            handleClickRemoveParam={handleClickRemoveParam}
                            handleClickAddParam={handleClickAddParam}
                            errors={{}}
                            params={linkTrackingParams}
                          />
                          <CheckBoxWrapper mt="m">
                            <Checkbox
                              isChecked={save_utm_tracking}
                              onClick={() => {
                                const { link } = post

                                link.save_utm_tracking = !save_utm_tracking
                                postDispatch({ type: 'link', data: { link } })
                              }}
                            >
                              <Text ml="m" fontSize="xs" pl="s">
                                Save for future use
                              </Text>
                            </Checkbox>
                          </CheckBoxWrapper>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                )}
            </StyledBox>
            <Flex px="m" my="m" flexDirection="column">
              <LabelsSelect
                noOptionMessage="Label your posts by adding new labels"
                setData={(post) => {
                  const { labels = [] } = post
                  postDispatch({ type: 'labels', data: { labels } })
                }}
                selectedLabels={labels}
                labelOptions={agencyLabels}
                setLabelOptions={setAgencyLabels}
                data={post}
                type={LABELS_TYPE_PUBLICATION}
              />

              <AdvocacyComponent
                advocacy={advocacy}
                isAdvocacyOnly={advocacy_gid}
                postDispatch={postDispatch}
                isAdvocate={isAdvocate}
                selectedEntities={selectedEntities}
              />
            </Flex>
          </Scrollbars>
        </PostWrapper>
        <Box width="55%">
          <PostPreviewComponent
            user={user}
            onChangeUpdateAllProfilesCustomizations={onChangeUpdateAllProfilesCustomizations}
            onChangeUpdateSpecificProfileCustomizations={onChangeUpdateSpecificProfileCustomizations}
            selectedEntities={selectedEntities}
            selectedProfiles={selectedProfiles}
            postForEdit={post}
            dataForCustomizations={dataForCustomizations}
            setDataForCustomizations={setDataForCustomizations}
            isPostFormDirty={isPostFormDirty}
            postErrors={postErrors}
            agencyMentions={agencyMentionsOptions.data}
            reminderNotificationsListByEntity={reminderNotificationsListByEntity}
            savedVariables={savedVariables}
            forceCloseNetworkPreviewCaption={forceCloseNetworkPreviewCaption}
            boosts={boostsOptions.data}
            isAdvocacy={isAdvocacy}
          />
        </Box>

        {openAIModalOptions.isOpen && (
          <OpenAIModal
            user={user}
            handleDismiss={handleClickCloseAIModal}
            handleUpdateText={handleUpdateTextFromAIModal}
            text={openAIModalOptions.text}
            isOpen={openAIModalOptions.isOpen}
            savedGeneratedTexts={openAIModalOptions.savedGeneratedTexts}
            saveOptions={({ text, generatedTexts }) => {
              openAIModalOptions.text = text
              openAIModalOptions.savedGeneratedTexts = generatedTexts
              setOpenAIModalOptions({ ...openAIModalOptions })
            }}
            type={AI_TYPE_POST}
            rephrase={openAIModalOptions.rephrase}
            generateOnOpen={openAIModalOptions.generateOnOpen}
          />
        )}

        {ai_assistant_prompt && (
          <StyledAIPromptRefine id="publish-modal-ai-prompt-refine" onClick={handleClickOpenAIModalRefineText}>
            <Image source="/assets/magic_wand.svg" width="20px" height="20px" />
            <Text ml="s">Refine with AI</Text>
          </StyledAIPromptRefine>
        )}

        <StyledAIPrompt id="ai-prompt" isVisible={!ai_assistant_prompt}>
          <Flex position="relative" alignItems="center" justifyContent="space-between">
            <Text color="primary" fontSize="xs">
              <Text as="span" color="primary" fontWeight="bold" fontSize="xs">
                New!
              </Text>{' '}
              Craft your social post with our AI Assistant
            </Text>
            <AddMediaWrapper ml="s" onClick={handleClickCloseAIPrompt}>
              <Icon.Dismiss width="10px" />
            </AddMediaWrapper>
            <Triangle />
          </Flex>
        </StyledAIPrompt>

        {variablesModalOptions.isOpen && (
          <VariablesModal
            user={user}
            handleSave={handleClickSaveUpdatedVariables}
            isOpen={variablesModalOptions.isOpen}
            profiles={variablesModalOptions.profiles}
            variables={variablesModalOptions.variables}
            callbackFunction={variablesModalOptions.callbackFunction}
            needValidation={variablesModalOptions.needValidation}
          />
        )}

        {compliancePreviewModalOptions.isOpen && (
          <CompliancePreviewModal
            isOpen={compliancePreviewModalOptions.isOpen}
            handleDismiss={handleClickCloseCompliancePreviewModal}
            user={user}
            entityIds={compliancePreviewModalOptions.entityIds}
          />
        )}

        <CalendarItemTooltip ref={tooltipRef} />
      </Flex>
    )
  }
)

PostCreationComponent.defaultProps = {
  selectedProfiles: [],
  selectedEntities: [],
  postForEdit: { customizations: {} },
  dataForCustomizations: {},
  checkForErrors: true,
  onChangeUpdatePost: () => {},
  uploadingMediasAmount: 0,
  setUploadingMediasAmount: () => {},
  postCreationComponentDataLoadingCallback: () => {},
  isAdvocacy: false,
  whenToPublish: '',
}

PostCreationComponent.propTypes = {
  user: PropTypes.object.isRequired,
  selectedProfiles: PropTypes.array,
  selectedEntities: PropTypes.array,
  onChangeUpdatePost: PropTypes.func,
  postForEdit: PropTypes.object,
  dataForCustomizations: PropTypes.object,
  checkForErrors: PropTypes.bool,
  uploadingMediasAmount: PropTypes.number,
  setUploadingMediasAmount: PropTypes.func,
  confirm: PropTypes.func.isRequired,
  postCreationComponentDataLoadingCallback: PropTypes.func,
  isAdvocacy: PropTypes.bool,
  whenToPublish: PropTypes.string,
}

PostCreationComponent.displayName = 'PostCreationComponent'

export default PostCreationComponent
